import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-fs-timeline-summary',
  templateUrl: './fs-timeline-summary.component.html',
  styleUrls: ['./fs-timeline-summary.component.scss']
})
export class FsTimelineSummaryComponent implements OnInit {

  @Input('showOpenButton') showOpenButton: boolean;
  @Output('onOpen') onOpen: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  openDetails() {
    this.onOpen.emit();
  }
}
