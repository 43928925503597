import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './core/layouts/auth-layout/auth-layout.component';
import { SiteLayoutComponent } from './core/layouts/site-layout/site-layout.component';
import { AuthGuard } from './core/shared/guards/auth.guard';
import { ProjectGuard } from './core/shared/guards/project.guard';


const childRoutes: Routes = [
  { path: '', loadChildren: () => import('./app-main/app-main.module').then(m => m.AppMainModule) },
  { path: 'questionnaire', loadChildren: () => import('./app-questionnaire/app-questionnaire.module').then(m => m.AppQuestionnaireModule) },
  { path: 'pricing-estimator', loadChildren: () => import('./app-pricing-estimator/app-pricing-estimator.module').then(m => m.AppPricingEstimatorModule) },
  { path: 'billing', loadChildren: () => import('./app-billing/app-billing.module').then(m => m.AppBillingModule) },
  { path: 'checkout', loadChildren: () => import('./app-checkout/app-checkout.module').then(m => m.AppCheckoutModule) },
  { path: 'tasks', loadChildren: () => import('./app-task/app-task.module').then(m => m.AppTaskModule) },
  { path: 'design', loadChildren: () => import('./app-design/app-design.module').then(m => m.AppDesignModule) },
  { path: 'strategy', loadChildren: () => import('./app-strategy/app-strategy.module').then(m => m.AppStrategyModule) },
  { path: 'team', loadChildren: () => import('./app-team/app-team.module').then(m => m.AppTeamModule) },
  { path: 'request', loadChildren: () => import('./app-request/app-request.module').then(m => m.AppRequestModule) },
  { path: 'search', loadChildren: () => import('./app-search/app-search.module').then(m => m.AppSearchModule) },
  { path: 'files', loadChildren: () => import('./app-file/app-file.module').then(m => m.AppFileModule) },
  { path: 'agreement', loadChildren: () => import('./app-legal-agreement/app-legal-agreement.module').then(m => m.AppLegalAgreementModule) },
];

const childAuthRoutes: Routes = [
  { path: '', loadChildren: () => import('./app-auth/app-auth.module').then(m => m.AppAuthModule) },
];

const childLegalRoutes: Routes = [
  { path: '', loadChildren: () => import('./app-legal/app-legal.module').then(m => m.AppLegalModule) },
];

const childHealthRoutes: Routes = [
  { path: '', loadChildren: () => import('./app-health/app-health.module').then(m => m.AppHealthModule) },
];

const childBuildRoutes: Routes = [
  { path: '', loadChildren: () => import('./app-build/app-build.module').then(m => m.AppBuildModule) },
];

const childNotFoundRoutes: Routes = [
  { path: '', loadChildren: () => import('./app-not-found/app-not-found.module').then(m => m.AppNotFoundModule) },
];

const routes: Routes = [
  {
    path: '',
    component: SiteLayoutComponent,
    children: childRoutes,
    canActivate: [ProjectGuard, AuthGuard],
    // canActivateChild: [AuthGuard]
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    children: childAuthRoutes,
  },
  {
    path: 'not-found',
    children: childNotFoundRoutes,
  },
  {
    path: 'legal',
    children: childLegalRoutes,
    canActivate: [ProjectGuard]
  },
  {
    path: 'health',
    children: childHealthRoutes,
    canActivate: [ProjectGuard]
  },
  {
    path: 'build',
    children: childBuildRoutes,
    canActivate: [ProjectGuard]
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
