import { Component, OnInit } from '@angular/core';
import { AuthDataService } from 'src/app/app-auth/shared/services/auth-data.service';
import { EventService } from '../../shared/services/event.service';
import { ProjectService } from '../../shared/services/project.service';
import { SessionService } from '../../shared/services/session.service';
import { getProjectSlug } from '../../shared/utils/utils';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {

  email: string;
  projectName: string;

  constructor(
    private authDataService: AuthDataService,
    private projectService: ProjectService,
    private sessionService: SessionService,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    this.authDataService.email.subscribe((email) => { this.email = email; });
    this.authDataService.projectName.subscribe((projectName) => { this.projectName = projectName; });

    this.sessionService._projectSubject.subscribe((project) => {
      this.projectName = project?.name;
    });
  }
}
