import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  transform(value: Date): unknown {
    if (!value) { return ''; }

    if (!(value instanceof Date))
      value = new Date(value);

    let seconds: number = Math.floor(((new Date()).getTime() - value.getTime()) / 1000);
    let interval: number = Math.floor(seconds / 31536000);

    if (interval > 1) {
      return interval + ' years ago';
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return interval + ' months ago';
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return interval + ' days ago';
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return interval + ' hours ago';
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return interval + ' minutes ago';
    }

    let secs = Math.floor(seconds);
    if (secs < 0) { secs = 0; }
    if (secs == 0) { return 'just now'; }
    if (secs >= 60 && secs <= 90) { return '1 minute ago'; }
    if (secs > 90 && secs <= 120) { return '1 minute ago'; }

    return secs + ' seconds ago';
  }
}
