import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-fs-progress-line',
  templateUrl: './fs-progress-line.component.html',
  styleUrls: ['./fs-progress-line.component.scss']
})
export class FsProgressLineComponent implements OnInit {

  @Input('totalSteps') totalSteps: number;
  @Input('currentStep') currentStep: number;

  get progressWidth(): string {
    return `${this.currentStep * 100 / this.totalSteps}%`;
  }

  constructor() { }

  ngOnInit(): void {

  }

}
