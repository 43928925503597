import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-fs-select',
  templateUrl: './fs-select.component.html',
  styleUrls: ['./fs-select.component.scss']
})
export class FsSelectComponent implements OnInit {

  @Input('fixedWidth') fixedWidth: boolean = false;
  @Input('selectOptions') selectOptions = [];
  @Input('label') label: string = 'Select Option';
  @Output('onSelectChange') onSelectChange: EventEmitter<string | number> = new EventEmitter();

  @ViewChild('selectContent') selectContent: ElementRef;

  isOpen = false;

  bodyHeight = '';
  widthFix = '';

  get selectedLabel() {
    return this.selectOptions.filter(x => x.selected)[0]?.value ?? this.label;
  }

  constructor() { }

  ngOnInit(): void {
    this.fixWidth();
  }

  changeSelection(key) {
    if (this.selectOptions.filter(x => x.selected == true).length > 0) {
      this.selectOptions.filter(x => x.selected == true)[0].selected = false;
    }
    this.selectOptions.forEach(x => { x.selected = false });
    this.selectOptions.filter(x => x.key == key)[0].selected = true;
    this.onSelectChange.emit(key);

    this.toggleSelect();
  }

  toggleSelect() {
    this.isOpen = !this.isOpen;
    this.bodyHeight = `${this.isOpen ? this.selectContent.nativeElement.offsetHeight : 0}px`;
  }

  private fixWidth() {
    let selectValues = this.selectOptions.map(x => x.value);
    this.widthFix = selectValues.sort((a, b) => b.length - a.length)[0];
  }
}
