import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-fs-timeline-chart',
  templateUrl: './fs-timeline-chart.component.html',
  styleUrls: ['./fs-timeline-chart.component.scss']
})
export class FsTimelineChartComponent implements OnInit {

  @ViewChild('container') container: ElementRef;

  timelineChartGridTranslate = '';
  containerHeight = null;
  containerWidth = null;
  isShown = false;

  sprints = [];
  timeline = { services: [] };

  constructor() { }

  ngOnInit(): void {
    this.fillTimeline();
    this.initTimeline();
    this.bindTimelineChartScroll();
  }

  sprintMouseOn(service, sprint) {
    this.timeline.services.forEach(x => x.active = false);
    this.sprints.forEach(x => x.active = false);

    service.active = true;
    sprint.active = true;
  }

  sprintMouseOff(service, sprint) {
    service.active = false;
    sprint.active = false;
  }

  toggle() {
    this.isShown = !this.isShown;
    this.containerHeight = this.isShown ? this.container.nativeElement.offsetHeight : 0;
  }

  private initTimeline() {

    this.timeline.services.forEach(service => {

      let maxHeight = 0;
      service.tasks.forEach(task => {
        let _width = this.calculateTaskWidth(task);
        let _top = this.calculateTaskTop(task);
        let _translateLeft = this.calculateTaskTranslateLeft(task);

        let width = `${_width}px`;
        let top = `${_top}px`
        let translate = `translateX(${_translateLeft}px)`;

        maxHeight = Math.max(maxHeight, _top + (16 + 15));

        let style = `width:${width}; top: ${top}; transform: ${translate}`;
        task.style = style;
      });

      service.style = `height: ${maxHeight}px`;
    });

    let sprintsCount = Math.max(...this.timeline.services.map(x => { return Math.max(...x.tasks.map(y => y.end)) }));
    for (let index = 0; index < sprintsCount + 0; index++) { this.sprints.push({}); }

    this.containerWidth = sprintsCount * 145 + 20;
  }

  private fillTimeline() {
    this.timeline = {
      services: [
        {
          name: 'Strategy', style: '',
          tasks: [
            { name: 'User Personas', start: 1, end: 1, order: 1, style: '' },
            { name: 'Sitemap', start: 1, end: 1, order: 2, style: '' },
            // { name: 'Dumping', start: 7, end: 7, order: 1, style: '' },
          ]
        },
        {
          name: 'Design', style: '',
          tasks: [
            { name: 'Wireframes', start: 2, end: 2, order: 1, style: '' },
            { name: 'Visual Identity', start: 2, end: 2, order: 2, style: '' },
            { name: 'High Fidelity', start: 3, end: 4, order: 3, style: '' },
            { name: 'Flow Map', start: 4, end: 4, order: 4, style: '' },
          ]
        },
        {
          name: 'Engineering', style: '',
          tasks: [
            { name: 'Architecture', start: 4, end: 4, order: 1, style: '' },
            { name: 'Frontend', start: 4, end: 7, order: 2, style: '' },
            { name: 'Backend', start: 4, end: 7, order: 3, style: '' },
            { name: 'Testing', start: 7, end: 15, order: 4, style: '' },
          ]
        },
      ]
    }
  }

  private calculateTaskWidth(task) {
    return (((task.end - task.start) + 1) * 143) + ((task.end - task.start) * 2);
  }

  private calculateTaskTop(task) {
    return ((task.order - 1) * 16) + ((task.order - 1) * 10) + (15);
  }

  private calculateTaskTranslateLeft(task) {
    return task.start == 1 ? 1 : ((task.start - 1) * 143) + ((task.start - 1) * 2) + 1;
  }

  private bindTimelineChartScroll() {
    document.getElementById("timelineChartContent").addEventListener("scroll", () => {
      var elmnt = document.getElementById("timelineChartContent");
      this.timelineChartGridTranslate = `translateX(-${elmnt.scrollLeft}px)`;
    });
  }

  private groupBy = key => array =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});
}
