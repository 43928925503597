<svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
        <path
            d="M29.631 7.36754C27.0044 4.74083 23.3693 3.56893 19.656 4.1428C14.9675 4.87423 11.0805 8.65099 10.2031 13.3282C9.78105 15.58 10.0237 17.8732 10.9057 19.9583C11.0184 20.2228 11.0529 20.4712 11.0081 20.6931L10.0225 25.6245C9.94665 26.0018 10.0651 26.3916 10.3365 26.663C10.5538 26.8804 10.8471 27 11.1495 27C11.2243 27 11.3002 26.9931 11.3749 26.977L16.3003 25.9926C16.5832 25.9385 16.8581 26.0179 17.0409 26.0938C19.127 26.9758 21.4201 27.2174 23.6706 26.7964C28.3476 25.919 32.1241 22.0318 32.8555 17.3431C33.434 13.6307 32.2587 9.9954 29.631 7.36754Z"
            fill="#FCEA10" />
        <path
            d="M29.631 7.36754C27.0044 4.74083 23.3693 3.56893 19.656 4.1428C14.9675 4.87423 11.0805 8.65099 10.2031 13.3282C9.78105 15.58 10.0237 17.8732 10.9057 19.9583C11.0184 20.2228 11.0529 20.4712 11.0081 20.6931L10.0225 25.6245C9.94665 26.0018 10.0651 26.3916 10.3365 26.663C10.5538 26.8804 10.8471 27 11.1495 27C11.2243 27 11.3002 26.9931 11.3749 26.977L16.3003 25.9926C16.5832 25.9385 16.8581 26.0179 17.0409 26.0938C19.127 26.9758 21.4201 27.2174 23.6706 26.7964C28.3476 25.919 32.1241 22.0318 32.8555 17.3431C33.434 13.6307 32.2587 9.9954 29.631 7.36754"
            stroke="white" />
    </g>
    <path fill-rule="evenodd" clip-rule="evenodd"
        d="M19.3575 19.6156L16.957 19.959L17.2998 17.558L23.4731 11.3851C24.0413 10.8169 24.9626 10.8169 25.5309 11.3851C26.0991 11.9533 26.0991 12.8746 25.5309 13.4428L19.3575 19.6156Z"
        stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M22.7891 12.0703L24.8468 14.1279" stroke="white" stroke-width="1.2" stroke-linecap="round"
        stroke-linejoin="round" />
    <defs>
        <filter id="filter0_d" x="0.5" y="0.5" width="42" height="42" filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
            <feOffset dy="6" />
            <feGaussianBlur stdDeviation="4.5" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.368113 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
    </defs>
</svg>