import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SearchFlowService } from 'src/app/app-search/shared/services/search-flow.service';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {

  private instance = 'menu_search';
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private searchFlowService: SearchFlowService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.searchFlowService.onSearchKeyEntered
      .subscribe((e) => {
        if (e.instance != this.instance) {
          this.form.patchValue({ search: e.search }, { onlySelf: true, emitEvent: false })
        }
      })
  }

  searchStart() { }

  clear() {
    this.form.patchValue({ search: '' });
  }

  submit({ value, valid }: { value: any, valid: boolean }) {
    if (valid) {
      this.router.navigate(['/search']);
      setTimeout(() => { this.searchFlowService.search(value); }, 10);
    }
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      search: ['', [Validators.required]]
    });

    this.form.get('search').valueChanges
      .subscribe((e) => {
        this.searchFlowService.searchKeyEnter(e, this.instance);
      })
  }
}
