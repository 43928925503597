import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { elementBelongsToTarget } from 'src/app/core/shared/utils/utils';
import { IActionButtonItem } from '../../models/action-button-item.model';

@Component({
  selector: 'app-fs-action-button',
  templateUrl: './fs-action-button.component.html',
  styleUrls: ['./fs-action-button.component.scss']
})
export class FsActionButtonComponent implements OnInit, AfterViewInit {

  opened: boolean;
  containerWidth: number;
  containerHeight: number;

  fromLeft: boolean;
  fromRight: boolean;
  fromTop: boolean;
  fromBottom: boolean;

  @ViewChild('contentPanel') contentPanel: ElementRef;
  @ViewChild('actionButtonContainer') actionButtonContainer: ElementRef;

  @Input('actions') actions: IActionButtonItem[] = [];

  constructor() { }

  ngAfterViewInit() {
    this.adjustClass();
  }

  ngOnInit(): void {

    fromEvent(document, 'click')
      .subscribe((e) => {
        if (this.opened == true) {
          if (elementBelongsToTarget(e.target, this.actionButtonContainer.nativeElement)) { this.toggle(); }
        }
      })
  }

  toggle() {
    this.opened = !this.opened;
    this.containerHeight = this.opened ? this.contentPanel.nativeElement.offsetHeight + 18 : 0;
    this.containerWidth = this.opened ? this.contentPanel.nativeElement.offsetWidth : 0;

    this.adjustClass();
  }

  private adjustClass() {
    let rect = this.actionButtonContainer.nativeElement.getBoundingClientRect();

    if (this.opened) {
      this.fromLeft = this.fromRight = this.fromTop = this.fromBottom = false;

      if ((window.innerWidth - rect.x) < this.containerWidth + 10) {
        this.fromRight = true;
      } else {
        this.fromLeft = true;
      }

      if ((window.innerHeight - rect.y) < this.containerHeight + 10) {
        this.fromBottom = true;
      } else {
        this.fromTop = true;
      }
    } else {
      setTimeout(() => {
        this.fromLeft = this.fromRight = this.fromTop = this.fromBottom = false;
      }, 130);
    }
  }
}
