import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { FsTimelineChartComponent } from '../fs-timeline-chart/fs-timeline-chart.component';

@Component({
  selector: 'app-fs-timeline',
  templateUrl: './fs-timeline.component.html',
  styleUrls: ['./fs-timeline.component.scss']
})
export class FsTimelineComponent implements OnInit {

  @ViewChild('timelineChart') timelineChart: FsTimelineChartComponent;

  @ViewChild('timelineDetailsContainer') timelineDetailsContainer: ElementRef;
  @ViewChild('timelineSummaryContainer') timelineSummaryContainer: ElementRef;

  constructor() { }

  ngOnInit(): void {
    fromEvent(document, 'click')
      .subscribe((e) => {

        if (this.timelineChart.isShown == true) {

          let x = true;
          let a = e.target as any;
          let els1 = [];

          if (e.target == this.timelineDetailsContainer.nativeElement) {
            x = false;
          }

          while (a && x) {
            if (a == this.timelineDetailsContainer.nativeElement) { x = false; }
            els1.unshift(a);
            a = a.parentNode;
          }

          if (x) { this.toggle(); }
        }

      })
  }

  toggle() {
    setTimeout(() => {
      this.timelineChart.toggle();
    }, 100);
  }
}