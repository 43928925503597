import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-fs-back-button',
  templateUrl: './fs-back-button.component.html',
  styleUrls: ['./fs-back-button.component.scss']
})
export class FsBackButtonComponent implements OnInit {

  @Input('text') text: string = 'Back';
  @Output('onClick') onClick: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  click(e) {
    this.onClick.emit(e);
  }
}
