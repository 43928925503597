import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from 'src/app/shared/services/modal.service';
import { ISessionUser } from '../../shared/models/session-user.model';
import { SessionService } from '../../shared/services/session.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {

  @Input('user') user: ISessionUser;
  @Output('onSettingsClick') onSettingsClick: EventEmitter<void> = new EventEmitter();

  @ViewChild('profileMenuPanel') profileMenuPanel: ElementRef;

  menuIsOpen = false;
  menuHeight = '';
  userMenuHeight = null;

  @ViewChild('userMenuPanel') userMenuPanel: ElementRef;

  constructor(
    private router: Router,
    private sessionService: SessionService,
    private modalService: ModalService,
  ) { }

  ngOnInit(): void {

  }

  toggleMenu() {
    this.menuIsOpen = !this.menuIsOpen;
    if (this.menuIsOpen) {
      this.userMenuHeight = this.userMenuPanel.nativeElement.offsetHeight;
    } else {
      this.closeMenu();
    }
  }

  uploadProfileImage() {
    this.modalService
      .showModal(import('../../../app-profile/components/upload-image/upload-image.component'))
      .subscribe();
  }

  logOut() {
    this.sessionService._removeSession();
    this.router.navigate(['auth/login']);
    this.toggleMenu();
  }

  settings() {
    this.onSettingsClick.emit();
    this.closeMenu();
  }

  closeMenu() {
    this.menuIsOpen = false;
    this.userMenuHeight = 55;
  }
}
