<div class="fs-select-list-container" [ngClass]="{'open' : isOpen}">
    <div class="select-list-header" (click)="toggle()" #listHeader>
        <div class="select-label" [innerHtml]="selectedValue"></div>
        <i class="arrow"></i>
    </div>
    <div class="select-list-content" [style.height]="listContentHeight + 'px'">
        <div class="select-list-content-panel" #listContentPanel>
            <ul>
                <li [style.height]="listItemHeight + 'px'" *ngFor="let value of valuesToSelect">
                    <a [innerHtml]="value.value" (click)="selectValue(value)"></a>
                </li>
            </ul>
        </div>
    </div>
</div>