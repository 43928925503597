<div class="drop-down">
    <div class="drop-down-header" [ngClass]="{'opened' : dropDownIsOpened}" (click)="toggleDropDown()" #dropDownOpener>
        {{ selectedLabel }}
        <i>
            <img src="../../../../assets/fs-arrow-down-white.svg" alt="">
        </i>
    </div>
    <div class="drop-down-body" [style.height]="dropDownHeight">
        <div class="drop-down-body-content" #dropDownContent>
            <div class="drop-down-items">
                <div class="drop-down-item" (click)="changeDropDown(item.key)"
                    *ngFor="let item of itemsToSelect; index as i">
                    {{ item.value }}
                </div>
            </div>
        </div>
    </div>
</div>