import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fs-leave-comment-icon',
  templateUrl: './fs-leave-comment-icon.component.html',
  styleUrls: ['./fs-leave-comment-icon.component.scss']
})
export class FsLeaveCommentIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
