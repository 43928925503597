import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fsnumber'
})
export class FsNumberPipe implements PipeTransform {

  constructor() { }

  transform(value: number): unknown {
    let strValue = value.toString();

    if (strValue.length == 5) {
      return `${strValue.substring(0, 2)},${strValue.substring(2, 5)}`
    }

    if (strValue.length == 4) {
      return `${strValue.substring(0, 1)},${strValue.substring(1, 4)}`
    }

    return value;
  }

}
