<section>
    <div class="nav-menu-container" [ngClass]="{'off':isOff}">
        <!-- <app-nav-menu></app-nav-menu> -->
        <app-navigation-menu (onToggle)="toggle($event)"></app-navigation-menu>
    </div>
    <div class="main-container" [ngClass]="{'off':isOff}" (dragenter)="onDragEnter($event)"
        (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)">
        <div class="project-timeline-container">
            <app-fs-timeline></app-fs-timeline>
        </div>
        <router-outlet></router-outlet>

        <app-modal></app-modal>
        <app-overlay-file-uploader></app-overlay-file-uploader>
    </div>
</section>
<app-notifications></app-notifications>