import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { EstimateService } from 'src/app/app-pricing-estimator/shared/services/estimate.service';
import { QuestionnaireService } from 'src/app/app-questionnaire/shared/services/questionnaire.service';
import { ISessionUser } from 'src/app/core/shared/models/session-user.model';
import { ProjectService } from 'src/app/core/shared/services/project.service';
import { SessionService } from 'src/app/core/shared/services/session.service';
import { getProjectSlug } from 'src/app/core/shared/utils/utils';
import { handleError } from 'src/app/shared/utils/utils';
import { environment } from 'src/environments/environment';
import { ProfileDataService } from './profile-data.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private baseUrl = `${environment.circuitApiUrl}/users`

  constructor(
    private http: HttpClient,
    private sessionService: SessionService,
    private profileDataSerice: ProfileDataService,
    private projectService: ProjectService,
    private estimateService: EstimateService,
    private questionnaireService: QuestionnaireService
  ) { }

  getProfile(): Observable<ISessionUser> {
    const url = `${this.baseUrl}/me`;
    return this.http.get(url)
      .pipe(
        map((resp: any) => { return resp; }),
        catchError(handleError));
  }

  refreshUser() {
    this.getProfile().subscribe((resp) => {
      this.sessionService._saveUser(resp);
      this.profileDataSerice.profile.next();
    });
  }

  refreshUser2(): Observable<void> {
    return of([])
      .pipe(
        mergeMap(() => {
          return combineLatest([
            this.getProfile().pipe(map((user) => { this.sessionService._saveUser(user); })),
            this.projectService.get(this.sessionService.project.slug)
              .pipe(
                mergeMap((project) => {
                  this.sessionService._saveProject(project);
                  return combineLatest([
                    (project.estimate ? this.estimateService.getById(project.estimate) : of(null))
                      .pipe(map((estimate) => { this.sessionService._saveEstimate(estimate); })),
                    (project.questionnaire ? this.questionnaireService.get(project.questionnaire) : of(null))
                      .pipe(map((questionnaire) => { this.sessionService._saveQuestionnaire(questionnaire) }))
                  ])
                })
              )
          ])
        }),
        catchError((err) => {
          this.sessionService._removeSession();
          return handleError(err);
        }),
        map(() => { return; }),
      )
  }

  updateProfile(name: string, avatar: string): Observable<void> {
    const url = `${this.baseUrl}/${this.sessionService.user.id}`;
    return this.http.put(url, {
      name: this.sessionService.user.name,
      avatar: avatar
    })
      .pipe(
        map(() => {
          return;
        }), catchError(handleError));
  }

  refreshProfile(projectSlug): Observable<void> {
    if (!projectSlug) { projectSlug = getProjectSlug(); }
    return !projectSlug ?
      of(null) :
      this.projectService.get(projectSlug)
        .pipe(
          mergeMap((project) => {
            this.sessionService._saveProject(project);
            return forkJoin([
              (this.sessionService.isAuthenticated && project.questionnaire ? this.questionnaireService.get(project.questionnaire) : of(null))
                .pipe(map((questionnaire) => { this.sessionService._saveQuestionnaire(questionnaire) })),
              (this.sessionService.isAuthenticated && project.estimate ? this.estimateService.getById(project.estimate) : of(null))
                .pipe(map((estimate) => { this.sessionService._saveEstimate(estimate) })),
              (this.sessionService.isAuthenticated ? this.getProfile() : of(null))
                .pipe(map((user) => { this.sessionService._saveUser(user) }))
            ])
              .pipe(
                catchError((err) => {
                  this.sessionService._removeSession();
                  return handleError(err);
                })
              )
          }),
          map(() => { return; })
        );
  }
}
