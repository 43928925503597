import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileDataService {

  profile = new Subject();

  constructor() { }
}
