<section class="nav-menu-section">
    <div class="header">
        <a [routerLink]="['/']" class="logo">
            <img src="../../../../assets/fs-logo.svg" alt="">
        </a>

        <div class="user-menu-panel">
            <app-user-menu [user]="user" (onSettingsClick)="openSettings()"></app-user-menu>
        </div>
    </div>

    <div class="nav-body-and-footer">
        <div class="nav-body" [style.min-height]="navBodyMinHeight + 'px'">
            <div class="nav-body-panel" #navBodyPanel [style.transform]="navBodyTransform">
                <div class="nav-body main">
                    <nav>
                        <ul>
                            <li *ngFor="let menu of menus; index as i"
                                [ngClass]="{'active' : menu.active, 'open' : menu.open, 'locked' : menu.locked, 'child-selected' : childIsSelected(menu)}">
                                <div class="menu-item" (click)='toggleMenuChild(menu, i)'>
                                    <div class="menu-item-info">
                                        <app-menu-icon [name]="menu.icon"
                                            [active]="menu.active || anyChildIsActive(menu)">
                                        </app-menu-icon>
                                        <div class="children-arrow">
                                            <i *ngIf="menu.children && menu.children.length > 0"></i>
                                        </div>
                                        <a>{{ menu.name }}</a>
                                    </div>
                                    <div class="menu-item-lock">
                                        <img *ngIf="menu.locked" src="../../../../assets/menu/fs-lock.svg" alt="">
                                    </div>
                                </div>
                                <div class="menu-child" [hidden]="!menu.children || menu.children.length == 0"
                                    [style.height]="menu.childHeight + 'px'">
                                    <div #menuChildPanel>
                                        <ul>
                                            <li *ngFor="let childMenu of menu.children"
                                                [ngClass]="{'active' : childMenu.active}">
                                                <a [routerLink]="childMenu.link">{{ childMenu.name }}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </nav>
                </div>

                <div class="nav-body settings">
                    <div class="nav-back">
                        <button (click)="navigateToMainMenu()"></button>
                        <h2>Settings</h2>
                    </div>
                    <nav>
                        <ul>
                            <li *ngFor="let menu of settingMenus; index as i"
                                [ngClass]="{'active' : menu.active, 'open' : menu.open}">
                                <div class="menu-item" (click)='toggleMenuChild(menu, i)'>
                                    <div class="menu-item-info">
                                        <app-menu-icon [name]="menu.icon" [active]="menu.active"></app-menu-icon>
                                        <div class="children-arrow">
                                            <i *ngIf="menu.children && menu.children.length > 0"></i>
                                        </div>
                                        <a>{{ menu.name }}</a>
                                    </div>
                                    <div class="menu-item-lock">
                                        <img *ngIf="menu.locked" src="../../../../assets/menu/fs-lock.svg" alt="">
                                    </div>
                                </div>
                                <div class="menu-child" [hidden]="!menu.children || menu.children.length == 0"
                                    [style.height]="menu.childHeight + 'px'">
                                    <div #menuChildPanel>
                                        <ul>
                                            <li *ngFor="let childMenu of menu.children">
                                                <a href="">{{ childMenu.name }}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>

                        </ul>
                    </nav>
                </div>
            </div>
        </div>

        <div class="footer">
            <app-search-input></app-search-input>
            <div class="auto-collapse-panel">
                <button>
                    <img src="../../../../assets/fs-arrow.svg" alt="">
                    <img src="../../../../assets/fs-arrow.svg" alt="">
                </button>
            </div>
        </div>
    </div>
</section>