export function getCurrentOrigin() {
    return location.origin;
}

export function getCurrentDomain() {
    return getCurrentOrigin().replace("https://", "").replace("http://", "");
}

export function getCurrentProtocol() {
    return getCurrentOrigin().replace(getCurrentDomain(), "");
}

export function getCurrentDomainRoot() {
    let currentDomain = getCurrentDomain();
    if (currentDomain.startsWith('www.')) {
        currentDomain = currentDomain.replace('www.', '');
        let splittedDomain = currentDomain.split('.');
        splittedDomain.shift();
        return splittedDomain.join('.');
    }
    if (currentDomain.startsWith('welcome.')) {
        return currentDomain.replace('welcome.', '');
    } else {
        let respDomain = currentDomain.split('.');
        respDomain.shift();
        return respDomain.join('.');
    }
}

export function getUrlForProject(projectSlug: string) {
    return `${getCurrentProtocol()}${projectSlug}.${getCurrentDomainRoot()}`;
}

export function getProjectSlug() {
    let currentOrigin = getCurrentOrigin();
    if (currentOrigin.indexOf('localhost') > -1) { return ''; }
    let currentDomainRoot = getCurrentDomainRoot();
    let projectName = currentOrigin.replace(getCurrentProtocol(), '').replace(currentDomainRoot, '');
    return projectName.replace('www.welcome.', '').replace('www.', '').replace('welcome.', '').replace('.', '');
}

export function getSlug() {
    let currentDomain = getCurrentDomain();
    let isLocalhost = currentDomain.indexOf('localhost') > -1;
    if (isLocalhost) { return ''; }
    return currentDomain.split('.')[0];
}

export function decodeUrlParameter(str: string) {
    return decodeURIComponent((str + '').replace(/\+/g, '%20'));
}

export function encodeUrlParameter(str: string) {
    return encodeURIComponent((str + '').replace('%20', '+'));
}

export function isTeamEmail(email: string) {
    return email.endsWith('@gmail.com');
}

export function elementBelongsToTarget(target, content) {
    let x = true;
    let a = target as any;
    let els1 = [];

    if (target == content) { x = false; }

    while (a && x) {
        if (a == content) { x = false; }
        els1.unshift(a);
        a = a.parentNode;
    }

    return x;
}