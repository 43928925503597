import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-fs-select-button',
  templateUrl: './fs-select-button.component.html',
  styleUrls: ['./fs-select-button.component.scss']
})
export class FsSelectButtonComponent implements OnInit {

  @Output('onClick') onClick: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  click() {
    this.onClick.emit();
  }
}
