import { Component, OnInit, QueryList, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { NotificationService } from '../../shared/services/notification.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  @ViewChild('notificationContent', { read: ViewContainerRef }) notificationContent: ViewContainerRef;

  constructor(
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    setTimeout(() => { this.initNotificationContent(); }, 10);
  }

  private initNotificationContent() {
    setTimeout(() => { this.notificationService.notificationContainer = this.notificationContent; }, 10);
  }
}
