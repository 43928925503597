<section>
    <div class="row-one">
        <div class="circle-range-label active" [ngClass]="{'active' : innerValue == 151 || innerValue == 270}">Features
        </div>
        <div class="circle-range-panel">
            <div class="circle-range-container">
                <div class="range-shape">
                    <img style="pointer-events: none;" src="../../../../assets/circular-range-shape.svg" alt="">
                </div>
                <div id="xxxx" class="circle-outer">
                    <div class="circle-inner">
                        <div class="picker" [style.transform]="rotateValue" [ngClass]="{'animating' : animating}">
                            <div class="picker-thumb-arc"
                                [ngClass]="{'arc-29' : innerValue == 29, 'arc-151' : innerValue == 151, 'arc-270' : innerValue == 270}">
                                <img style="pointer-events: none;" src="../../../../assets/fs-circle-arc.svg" alt="">
                            </div>
                        </div>
                    </div>

                    <div class="circle-outer-two" #circleOuter (mousedown)="circleMouseDown($event)">
                        <div class="circle-inner-two">
                            <div class="picker" [style.transform]="rotateValue" [ngClass]="{'animating' : animating}">
                                <div class="picker-thumb" (mousedown)="startDragging()">
                                    <div class="thumb-lines" [style.transform]="thumbLinesRotate"
                                        [ngClass]="{'animating' : animatingThumb}">
                                        <div class="thumb-line"></div>
                                        <div class="thumb-line"></div>
                                        <div class="thumb-line"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="circle-range-label" [ngClass]="{'active' : innerValue == 29 || innerValue == 270}">Budget</div>
    </div>
    <div class="row-two">
        <div class="circle-range-label" [ngClass]="{'active' : innerValue == 29 || innerValue == 151}">Speed</div>
    </div>
</section>