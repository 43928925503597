import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchFlowService {

  @Output('onSearchKeyEntered') onSearchKeyEntered: EventEmitter<any> = new EventEmitter();
  @Output('onSearch') onSearch: EventEmitter<string> = new EventEmitter();

  constructor() { }

  searchKeyEnter(search: string, instance: string) {
    this.onSearchKeyEntered.emit({ instance: instance, search: search });
  }

  search(search: string) {
    this.onSearch.emit(search);
  }
}
