import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toInitials'
})
export class ToInitialsPipe implements PipeTransform {

  transform(value: string): string {
    let initials = '';
    let splitName = value?.split(' ');

    if (splitName.length > 0) {
      splitName.forEach(x => { initials += x.charAt(0); });
      return initials.toUpperCase();
    }

    return value;
  }

}
