import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ISelect } from '../../models/select.model';

@Component({
  selector: 'app-fs-select-list',
  templateUrl: './fs-select-list.component.html',
  styleUrls: ['./fs-select-list.component.scss']
})
export class FsSelectListComponent implements OnInit {

  @Input('selectLabel') selectLabel = 'Select Value';
  @Input('values') values: ISelect[] = [];

  @Output('onSelect') onSelect: EventEmitter<number | string> = new EventEmitter();

  listContentHeight = null;
  listItemHeight = null;
  isOpen = false;

  @ViewChild('listContentPanel') listContentPanel: ElementRef;
  @ViewChild('listHeader') listHeader: ElementRef;

  constructor(
    private renderer: Renderer2
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (e.target != this.listHeader.nativeElement
        && Array.from(this.listHeader.nativeElement.children).indexOf(e.target) == -1) {
        this.close();
      }
    })
  }

  ngOnInit(): void {
    setTimeout(() => { this.initListItemHeight(); }, 10);
  }

  get selectedValue() {
    return this.values.filter(x => x.selected == true)[0]?.value ?? this.selectLabel;
  }

  get valuesToSelect() {
    return this.values.filter(x => x.selected != true);
  }

  selectValue(value: ISelect) {
    this.values.forEach(x => { x.selected = false });
    value.selected = true;
    this.onSelect.emit(value.id)
  }

  toggle() {
    this.isOpen = !this.isOpen;
    this.listContentHeight = this.isOpen ? this.listContentPanel.nativeElement.offsetHeight + 0 : 0;
  }

  private close() {
    this.isOpen = false;
    this.listContentHeight = 0;
  }

  private initListItemHeight() {
    this.listItemHeight = this.listHeader.nativeElement.offsetHeight;
  }
}
