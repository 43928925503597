import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav-body',
  templateUrl: './nav-body.component.html',
  styleUrls: ['./nav-body.component.scss']
})
export class NavBodyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
