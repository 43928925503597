<div class="search-input" [ngClass]="{'active' : form.get('search').value.length > 0}">
    <form [formGroup]="form" novalidate (onSubmit)="submit(form)" (keyup.enter)="submit(form)">
        <div class="search-input-panel">
            <i class="icon"></i>
            <input type="text" placeholder="Search" formControlName="search">
            <div class="button-container">
                <button type="button" [hidden]="form.get('search').value.length == 0" (click)="clear()"></button>
            </div>
        </div>
    </form>
</div>