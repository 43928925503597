<section>
    <div class="header-container" [ngClass]="{'off':isOff}">
        <div class="logo-panel">
            <img class="logo full" src="../../../../assets/fs-nav-logo-full.svg" alt="">
            <img class="logo collapsed" src="../../../../assets/fs-nav-logo-collapsed.svg" alt="">
        </div>
        <div class="profile-panel" [style.height]="profilePanelHeight + 'px'" [ngClass]="{
                'off' : isOff,
                'opened' : profileMenuIsOpened
            }">
            <div class="profile-header" #profileHeader (click)="toggleProfileMenu()">
                <div class="project-details">
                    <h3>YachtNow</h3>
                    <h4>Project Portal</h4>
                    <h6>yachtnow.faststartup.io</h6>
                </div>
                <div class="user-profile">
                    <img src="../../../../assets/user-image.png" alt="">
                    <button></button>
                </div>
            </div>

            <div class="profile-menu" #profileMenu>
                <div class="user-details">
                    <h2>Melvyn Kim</h2>
                    <h5>melvyn@faststartup.io</h5>
                </div>
                <div class="splitter"></div>
                <div class="user-nav-menu">
                    <nav>
                        <ul>
                            <li *ngFor="let userMenuItem of userMenuItems">
                                <a class="user-nav-item" (click)="userMenuClick(userMenuItem)">
                                    <div class="user-nav-icon-container">
                                        <app-menu-icon [name]="userMenuItem.icon">
                                        </app-menu-icon>
                                    </div>
                                    <div class="user-nav-name">
                                        {{ userMenuItem.name }}
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div class="user-projects">
                    <h3>Your Projects</h3>
                    <div class="splitter"></div>
                    <div class="project-list">
                        <a class="project-item" *ngFor="let userProject of userProjects">
                            <div class="project-details">
                                <h3>{{ userProject.name }}</h3>
                                <p>{{ userProject.domain }}</p>
                            </div>
                            <i></i>
                        </a>
                        <a class="project-item add">
                            <div class="project-details">
                                <p>Start a new project</p>
                            </div>
                            <i></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="body-and-footer-container" (click)="bodyAndFooterContainerClick()">

        <div class="nav-container" [ngClass]="{'off':isOff}">
            <div class="nav-body-panel">

                <div class="nav-body" *ngIf="!settingsMenuIsShown">
                    <nav>
                        <ul>
                            <li *ngFor="let menuItem of menuItems; index as i">
                                <a class="menu-item" (click)="menuItemClick(menuItem, i)" [ngClass]="{
                                    'locked' : menuItem.locked, 
                                    'active' : !menuItem.locked, 
                                    'selected' : menuItem.selected, 
                                    'opened' : menuItem.opened, 
                                    'has-children' : menuItem.children && menuItem.children.length > 0,
                                    'child-selected' : childIsSelected(menuItem)
                                }">
                                    <div class="menu-item-info">
                                        <div class="menu-icon-container">
                                            <app-menu-icon [name]="menuItem.icon"
                                                [active]="menuItem.selected || childIsSelected(menuItem)">
                                            </app-menu-icon>
                                        </div>
                                        <div class="menu-arrow-icon-container"></div>
                                        <div class="menu-name-container">
                                            {{ menuItem.name }}
                                        </div>
                                    </div>
                                    <div class="menu-item-lock"></div>
                                </a>
                                <div class="menu-children" [style.height]="menuItem.childrenPanelHeight + 'px'"
                                    [hidden]="!(menuItem.children && menuItem.children.length)">
                                    <div class="menu-children-panel" #menuChildPanel>
                                        <ul>
                                            <li *ngFor="let menuChild of menuItem.children">
                                                <a class="menu-child" (click)="menuChildClick(menuChild)" [ngClass]="{
                                                    'locked' : menuChild.locked, 
                                                    'active' : !menuChild.locked, 
                                                    'selected' : menuChild.selected 
                                                }">
                                                    <div class="menu-child-name-container">{{ menuChild.name }}</div>
                                                    <div class="menu-child-lock"></div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="menu-splitter" *ngIf="menuItem.hasSplitter"></div>
                            </li>
                        </ul>
                    </nav>
                </div>

                <div class="nav-body" *ngIf="settingsMenuIsShown">
                    <button class="btn-back" (click)="closeSettingsMenu()"> <i></i> Settings</button>
                    <nav>
                        <ul>
                            <li *ngFor="let menuItem of settingMenuItems; index as i">
                                <a class="menu-item" (click)="menuItemClick(menuItem, i)" [ngClass]="{
                                    'locked' : menuItem.locked, 
                                    'active' : !menuItem.locked, 
                                    'selected' : menuItem.selected, 
                                    'opened' : menuItem.opened, 
                                    'has-children' : menuItem.children && menuItem.children.length > 0,
                                    'child-selected' : childIsSelected(menuItem)
                                }">
                                    <div class="menu-item-info">
                                        <div class="menu-icon-container">
                                            <app-menu-icon [name]="menuItem.icon"
                                                [active]="menuItem.selected || childIsSelected(menuItem)">
                                            </app-menu-icon>
                                        </div>
                                        <div class="menu-arrow-icon-container"></div>
                                        <div class="menu-name-container">
                                            {{ menuItem.name }}
                                        </div>
                                    </div>
                                    <div class="menu-item-lock"></div>
                                </a>
                                <div class="menu-children" [style.height]="menuItem.childrenPanelHeight + 'px'"
                                    [hidden]="!(menuItem.children && menuItem.children.length)">
                                    <div class="menu-children-panel" #settingMenuChildPanel>
                                        <ul>
                                            <li *ngFor="let menuChild of menuItem.children">
                                                <a class="menu-child" (click)="menuChildClick(menuChild)" [ngClass]="{
                                                    'locked' : menuChild.locked, 
                                                    'active' : !menuChild.locked, 
                                                    'selected' : menuChild.selected 
                                                }">
                                                    <div class="menu-child-name-container">{{ menuChild.name }}</div>
                                                    <div class="menu-child-lock"></div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="menu-splitter" *ngIf="menuItem.hasSplitter"></div>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>


        <div class="search-container" [ngClass]="{'off':isOff}">
            <div class="search-panel">
                <button (click)="searchClick()"></button>
                <input type="text" placeholder="Search">
            </div>
            <div class="collapse-panel">
                <button (click)="toggle()">
                    <img src="../../../../assets/fs-collapse-arrow.svg" alt="">
                    <img src="../../../../assets/fs-collapse-arrow.svg" alt="">
                </button>
            </div>
        </div>
    </div>
</section>