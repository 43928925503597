import { Directive, ElementRef, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[appDragAndDrop]'
})
export class DragAndDropDirective {

  // private element;

  // @HostBinding('class.fileover') fileover: boolean;

  // @HostListener('dragover', ['$event']) onDragOver(evt) {
  //   if (!this.fileover) {
  //     this.fileover = true;
  //   }

  //   evt.preventDefault();
  //   evt.stopPropagation();
  // }

  // @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
  //   if (this.fileover) {
  //     this.fileover = false;
  //   }

  //   evt.preventDefault();
  //   evt.stopPropagation();
  // }

  // @HostListener('drop', ['$event']) public onDrop(evt) {
  //   if (this.fileover) {
  //     this.fileover = false;
  //     const files = evt.dataTransfer.files;
  //     if (files.length > 0) {
  //       console.log(files);
  //     }
  //   }

  //   evt.preventDefault();
  //   evt.stopPropagation();
  // }

  // constructor(private elRef: ElementRef) {
  //   this.element = this.elRef.nativeElement;
  // }

}
