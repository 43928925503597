import { Component, ElementRef, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from '../../shared/services/session.service';

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss']
})
export class NavigationMenuComponent implements OnInit {

  menuItems = [
    { name: 'Home', icon: 'home', locked: false, children: [], selected: false, link: ['/'], activeRoutes: ['/', '/questionnaire', '/pricing-estimator'] },
    { name: 'My Inbox', icon: 'myInbox', locked: true, children: [], selected: false, hasSplitter: true },
    { name: 'Feedback', icon: 'feedback', locked: false, children: [], selected: false, link: ['/request/feedbacks'], activeRoutes: ['/request/feedbacks'] },
    { name: 'Requests', icon: 'requests', locked: false, children: [], selected: false, link: ['/request/feature-requests'], activeRoutes: ['/request/feature-requests'] },
    { name: 'Task List', icon: 'taskList', locked: false, children: [], selected: false, hasSplitter: true, link: ['/tasks'], activeRoutes: ['/tasks'] },
    {
      name: 'Strategy',
      icon: 'strategy',
      opened: false,
      locked: true,
      childrenPanelHeight: null,
      children: [
        { name: 'User Persona', locked: false, selected: false, link: ['strategy/user-personas'] },
        { name: 'Mind Map', locked: false, selected: false }
      ],
      selected: false
    },
    {
      name: 'Design',
      icon: 'design',
      locked: true,
      childrenPanelHeight: null,
      children: [
        { name: 'Wireframes', locked: true, selected: false },
        { name: 'Visual Identity', locked: true, selected: false, link: ['/design/visual-identity'], activeRoutes: ['/design/visual-identity'] },
        { name: 'High Fidelity', locked: true, selected: false },
        { name: 'Flow Map', locked: true, selected: false },
      ],
      selected: false
    },
    {
      name: 'Engineering',
      icon: 'engineering',
      locked: true,
      childrenPanelHeight: null,
      children: [
        { name: 'Landing', locked: true, selected: false, link: ['/design/visual-identity'] },
        { name: 'Web App', locked: true, selected: false },
        { name: 'Admin Dashboard', locked: true, selected: false },
        { name: 'iOS App', locked: true, selected: false },
        { name: 'Android App', locked: true, selected: false },
      ],
      selected: false
    },
    { name: 'Files', icon: 'files', locked: false, children: [], selected: false, link: ['/files'], activeRoutes: ['/files'] },
  ];

  settingMenuItems = [
    { name: 'Account', icon: 'account', locked: false, children: [], selected: false },
    { name: 'Team', icon: 'team', locked: false, children: [], selected: false, link: ['/team'], activeRoutes: ['/team'] },
    { name: 'Project', icon: 'project', locked: false, children: [], selected: false },
    { name: 'Budget', icon: 'budget', locked: false, children: [], selected: false },
    { name: 'Billing', icon: 'billing', locked: false, children: [], selected: false, link: ['/billing'], activeRoutes: ['/billing/edit'] },
    { name: 'Legal', icon: 'legal', locked: false, children: [], selected: false },
    { name: 'Notifications', icon: 'notifications', locked: false, children: [], selected: false },
  ];

  userMenuItems = [
    {
      name: 'Settings', icon: 'settings', click: () => {
        this.toggleProfileMenu();
        this.openSettingsMenu()
      }
    },
    { name: 'Help & Feedback', icon: 'helpAndFeedback' },
    { name: 'Budget', icon: 'budget' },
    { name: 'Legal', icon: 'legal' },
    {
      name: 'Log Out', icon: 'logOut', click: () => {
        this.sessionService._removeSession();
        this.router.navigate(['auth/login']);
        this.toggleProfileMenu();
      }
    },
  ];

  userProjects = [
    { name: 'Hex', domain: 'hex.faststartup.io' },
    { name: 'Pandemos', domain: 'pandemos.faststartup.io' },
  ];

  @ViewChildren("menuChildPanel") menuChildPanels: QueryList<ElementRef>;
  @ViewChild("profileMenu") profileMenu: ElementRef;
  @ViewChild("profileHeader") profileHeader: ElementRef;

  @Output('onToggle') onToggle: EventEmitter<boolean> = new EventEmitter();

  isOff = false;
  profileMenuIsOpened = false;
  settingsMenuIsShown = false;

  profilePanelHeight = null;

  constructor(
    private router: Router,
    private sessionService: SessionService,
  ) { }

  childIsSelected(menuItem) {
    return menuItem && menuItem.children && menuItem.children.filter(x => x.selected).length > 0;
  }

  menuItemClick(menuItem, index) {
    if (menuItem.children?.length > 0 && !menuItem.locked) {
      if (!this.isOff) {
        menuItem.opened = !menuItem.opened;
        menuItem.childrenPanelHeight = menuItem.opened ? this.menuChildPanels.find((_, i) => i == index).nativeElement.offsetHeight + 0 : 0;
      }
    } else {
      if (menuItem.link) {
        this.router.navigate(menuItem.link);
      }
    }
  }

  menuChildClick(menuChild) {

  }

  toggleProfileMenu() {
    if (!this.isOff) {
      this.profileMenuIsOpened = !this.profileMenuIsOpened;
      let profileHeaderHeight = this.profileHeader.nativeElement.offsetHeight;
      this.profilePanelHeight = this.profileMenuIsOpened ? this.profileMenu.nativeElement.offsetHeight + profileHeaderHeight - 15 : profileHeaderHeight;
    }
  }

  ngOnInit(): void {
    this.manageMenuItemActiveStatus();

    this.router.events.subscribe(() => {
      this.manageMenuItemActiveStatus();
    });
  }

  bodyAndFooterContainerClick() {
    if (this.profileMenuIsOpened) {
      this.toggleProfileMenu();
    }
  }

  searchClick() {
    if (this.isOff) { this.toggle(); }
  }

  toggle() {
    let hasOpenedChildren = this.menuItems.filter(x => x.opened == true).length > 0;
    this.menuItems.forEach(x => { x.opened = false; x.childrenPanelHeight = 0 });

    setTimeout(() => {
      this.isOff = !this.isOff;
      this.onToggle.emit(this.isOff);
    }, hasOpenedChildren ? 100 : 0);
  }

  closeSettingsMenu() {
    this.settingsMenuIsShown = false;
  }

  openSettingsMenu() {
    this.settingsMenuIsShown = true;
  }

  userMenuClick(userMenuItem) {
    if (userMenuItem.click) {
      userMenuItem.click();
    }
  }

  private manageMenuItemActiveStatus() {

    [...this.menuItems, ...this.settingMenuItems].forEach((navMenuItem) => {
      if (navMenuItem.activeRoutes) {
        navMenuItem.selected = navMenuItem.activeRoutes.indexOf(this.router.url) != -1;
      }
      if (navMenuItem.children) {
        navMenuItem.children.forEach((x) => {
          if (x.activeRoutes) {
            x.active = x.activeRoutes.indexOf(this.router.url) != -1;
          }
        })
      }
    });

  }
}
