import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { handleError } from 'src/app/shared/utils/utils';
import { environment } from 'src/environments/environment';
import { IEstimate } from '../models/estimate.model';

@Injectable({
  providedIn: 'root'
})
export class EstimateService {

  private baseUrl = `${environment.circuitApiUrl}/estimates`;

  constructor(
    private http: HttpClient
  ) { }

  get(shortCode: string): Observable<IEstimate> {
    const url = `${this.baseUrl}/${shortCode}`;
    return this.http.get(url).pipe(
      map((resp: any) => {
        return this.mapToEstimate(resp);
      }),
      catchError(handleError))
  }

  getById(id: string): Observable<IEstimate> {
    const url = `${this.baseUrl}/by-id/${id}`;
    return this.http.get(url).pipe(
      map((resp: any) => {
        return this.mapToEstimate(resp);
      }),
      catchError(handleError))
  }

  post(
    questionOne: string, questionTwo: string,
    projectTemplates: string[],
    industries: string[], platforms: string[], features: string[]
  ): Observable<IEstimate> {
    let data = {
      phase: {
        questionOne: questionOne,
        questionTwo: questionTwo
      },
      projectTemplates: projectTemplates,
      industries: industries,
      platforms: platforms,
      features: features
    };

    const url = `${this.baseUrl}`
    return this.http.post(url, data)
      .pipe(
        map((resp: any) => {
          return this.mapToEstimate(resp);
        }),
        catchError(handleError)
      )
  }

  private mapToEstimate(estimate) {
    return estimate as IEstimate;
  }

}
