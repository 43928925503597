<section class="timeline-chart" [style.height]="containerHeight + 'px'">

    <div #container class="container">

        <div class="timeline-chart-header">
            <div class="header-summary">
                <app-fs-timeline-summary></app-fs-timeline-summary>
            </div>

            <div class="header-note">
                DELIVERABLES TIMELINE: <span>7 SPRINTS (21 weeks)</span>
            </div>
        </div>

        <div class="timeline-chart-body">

            <div class="timeline-chart-container">
                <div class="chart-names">
                    <div class="service-names">
                        <div class="service-name" *ngFor="let service of timeline.services" [style]="service.style">
                            <div class="service-name-panel">
                                {{ service.name }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="chart-content" id="timelineChartContent">
                    <div class="chart-panel" [style.width]="containerWidth + 'px'">

                        <div class="service" *ngFor="let service of timeline.services; index as i"
                            [style]="service.style">
                            <div class="fake-sprints">
                                <div class="sprint" (mouseenter)="sprintMouseOn(service, sprint)"
                                    (mouseleave)="sprintMouseOff(service, sprint)"
                                    *ngFor="let sprint of sprints; index as k">
                                </div>
                            </div>

                            <div class="task" [style]="task.style" *ngFor="let task of service.tasks">
                                <div class="task-panel">
                                    <div class="task-progress"></div>
                                    <div class="task-name">{{ task.name }}</div>
                                </div>
                            </div>
                        </div>

                        <div class="sprint-names">
                            <div class="sprint-name" *ngFor="let sprint of sprints; index as i">
                                {{ i == 0 ? 'Consultation' : 'Sprint ' + (i + 1) }}
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="timeline-grid-container" id="timelineChartGridContainer"
                [style.transform]="timelineChartGridTranslate">
                <div class="timeline-grid-panel" [style.width]="containerWidth + 'px'">
                    <table>
                        <tbody>

                            <tr *ngFor="let service of timeline.services" [style]="service.style">
                                <td *ngFor="let sprint of sprints"
                                    [ngClass]="{'active' : sprint.active && service.active}"></td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="timeline-chart-footer">
            <div class="footer-note">
                *CONSULTATION TAKES 1-2 weeks, EACH SPRINT IS 3 WEEKS
            </div>
            <button (click)="toggle()">
                <img src="../../../../assets/fs-open-timeline-black.svg" alt="">
                Hide
            </button>
        </div>
    </div>
</section>