import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionService } from '../shared/services/session.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(public sessionService: SessionService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token = this.sessionService?.token?.value;

        let notAuthorizedApis = ['project-templates', 's3', 'industries', 'platforms']; //, 'estimates'
        let addBearer = true;

        notAuthorizedApis.forEach((x) => {
            if (request.url.indexOf(x) != -1) { addBearer = false; }
        });

        if (token && addBearer) {
            request = request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
        }
        return next.handle(request);
    }
}