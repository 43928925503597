import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-fs-triangle-arrow',
  templateUrl: './fs-triangle-arrow.component.html',
  styleUrls: ['./fs-triangle-arrow.component.scss']
})
export class FsTriangleArrowComponent implements OnInit {

  @Input('stroke') stroke: string;
  @Input('fill') fill: string;

  constructor() { }

  ngOnInit(): void {
  }

}
