<svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
        <path
            d="M29.631 7.36754C27.0044 4.74083 23.3693 3.56893 19.656 4.1428C14.9675 4.87423 11.0805 8.65099 10.2031 13.3282C9.78105 15.58 10.0237 17.8732 10.9057 19.9583C11.0184 20.2228 11.0529 20.4712 11.0081 20.6931L10.0225 25.6245C9.94665 26.0018 10.0651 26.3916 10.3365 26.663C10.5538 26.8804 10.8471 27 11.1495 27C11.2243 27 11.3002 26.9931 11.3749 26.977L16.3003 25.9926C16.5832 25.9385 16.8581 26.0179 17.0409 26.0938C19.127 26.9758 21.4201 27.2174 23.6706 26.7964C28.3476 25.919 32.1241 22.0318 32.8555 17.3431C33.434 13.6307 32.2587 9.9954 29.631 7.36754Z"
            fill="var(--fs-red)" />
        <path
            d="M29.631 7.36754C27.0044 4.74083 23.3693 3.56893 19.656 4.1428C14.9675 4.87423 11.0805 8.65099 10.2031 13.3282C9.78105 15.58 10.0237 17.8732 10.9057 19.9583C11.0184 20.2228 11.0529 20.4712 11.0081 20.6931L10.0225 25.6245C9.94665 26.0018 10.0651 26.3916 10.3365 26.663C10.5538 26.8804 10.8471 27 11.1495 27C11.2243 27 11.3002 26.9931 11.3749 26.977L16.3003 25.9926C16.5832 25.9385 16.8581 26.0179 17.0409 26.0938C19.127 26.9758 21.4201 27.2174 23.6706 26.7964C28.3476 25.919 32.1241 22.0318 32.8555 17.3431C33.434 13.6307 32.2587 9.9954 29.631 7.36754"
            stroke="white" />
    </g>
    <path
        d="M26.6098 16.7782C25.9045 16.7782 25.332 16.2058 25.332 15.5004C25.332 14.7951 25.9045 14.2227 26.6098 14.2227C27.3151 14.2227 27.8876 14.7951 27.8876 15.5004C27.8876 16.2058 27.3151 16.7782 26.6098 16.7782Z"
        fill="white" />
    <path
        d="M21.5004 16.7782C20.7951 16.7782 20.2227 16.2058 20.2227 15.5004C20.2227 14.7951 20.7951 14.2227 21.5004 14.2227C22.2058 14.2227 22.7782 14.7951 22.7782 15.5004C22.7782 16.2058 22.2058 16.7782 21.5004 16.7782Z"
        fill="white" />
    <path
        d="M16.3872 16.7782C15.6818 16.7782 15.1094 16.2058 15.1094 15.5004C15.1094 14.7951 15.6818 14.2227 16.3872 14.2227C17.0925 14.2227 17.6649 14.7951 17.6649 15.5004C17.6649 16.2058 17.0925 16.7782 16.3872 16.7782Z"
        fill="white" />
    <defs>
        <filter id="filter0_d" x="0.5" y="0.5" width="42" height="42" filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
            <feOffset dy="6" />
            <feGaussianBlur stdDeviation="4.5" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.368113 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
    </defs>
</svg>