import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthDataService {

  // projectId: BehaviorSubject<string> = new BehaviorSubject<string>('');
  
  projectName: BehaviorSubject<string> = new BehaviorSubject<string>('');
  email: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor() { }

}
