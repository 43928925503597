<div class="img-container" [ngClass]="{'log-out' : name == 'logOut'}">

  <img *ngIf="name == 'home' && !active" src="../../../../assets/menu/v2/fs-home.svg" alt="">
  <img *ngIf="name == 'home' && active" src="../../../../assets/menu/v2/fs-home-active.svg" alt="">

  <img *ngIf="name == 'myInbox' && !active" src="../../../../assets/menu/v2/fs-my-inbox.svg" alt="">
  <img *ngIf="name == 'myInbox' && active" src="../../../../assets/menu/v2/fs-my-inbox-active.svg" alt="">

  <img *ngIf="name == 'feedback' && !active" src="../../../../assets/menu/v2/fs-feedback.svg" alt="">
  <img *ngIf="name == 'feedback' && active" src="../../../../assets/menu/v2/fs-feedback-active.svg" alt="">

  <img *ngIf="name == 'requests' && !active" src="../../../../assets/menu/v2/fs-requests.svg" alt="">
  <img *ngIf="name == 'requests' && active" src="../../../../assets/menu/v2/fs-requests-active.svg" alt="">

  <img *ngIf="name == 'taskList' && !active" src="../../../../assets/menu/v2/fs-task-list.svg" alt="">
  <img *ngIf="name == 'taskList' && active" src="../../../../assets/menu/v2/fs-task-list-active.svg" alt="">

  <img *ngIf="name == 'strategy' && !active" src="../../../../assets/menu/v2/fs-strategy.svg" alt="">
  <img *ngIf="name == 'strategy' && active" src="../../../../assets/menu/v2/fs-strategy-active.svg" alt="">

  <img *ngIf="name == 'design' && !active" src="../../../../assets/menu/v2/fs-design.svg" alt="">
  <img *ngIf="name == 'design' && active" src="../../../../assets/menu/v2/fs-design-active.svg" alt="">

  <img *ngIf="name == 'engineering' && !active" src="../../../../assets/menu/v2/fs-engineering.svg" alt="">
  <img *ngIf="name == 'engineering' && active" src="../../../../assets/menu/v2/fs-engineering-active.svg" alt="">

  <img *ngIf="name == 'files' && !active" src="../../../../assets/menu/v2/fs-files.svg" alt="">
  <img *ngIf="name == 'files' && active" src="../../../../assets/menu/v2/fs-files-active.svg" alt="">




  <img *ngIf="name == 'account' && !active" src="../../../../assets/menu/v2/fs-account.svg" alt="">
  <img *ngIf="name == 'account' && active" src="../../../../assets/menu/v2/fs-account-active.svg" alt="">

  <img *ngIf="name == 'team' && !active" src="../../../../assets/menu/v2/fs-team.svg" alt="">
  <img *ngIf="name == 'team' && active" src="../../../../assets/menu/v2/fs-team-active.svg" alt="">

  <img *ngIf="name == 'project' && !active" src="../../../../assets/menu/v2/fs-project.svg" alt="">
  <img *ngIf="name == 'project' && active" src="../../../../assets/menu/v2/fs-project-active.svg" alt="">

  <img *ngIf="name == 'billing' && !active" src="../../../../assets/menu/v2/fs-billing.svg" alt="">
  <img *ngIf="name == 'billing' && active" src="../../../../assets/menu/v2/fs-billing-active.svg" alt="">

  <img *ngIf="name == 'legal' && !active" src="../../../../assets/menu/v2/fs-legal.svg" alt="">
  <img *ngIf="name == 'legal' && active" src="../../../../assets/menu/v2/fs-legal-active.svg" alt="">

  <img *ngIf="name == 'notifications' && !active" src="../../../../assets/menu/v2/fs-notifications.svg" alt="">
  <img *ngIf="name == 'notifications' && active" src="../../../../assets/menu/v2/fs-notifications-active.svg" alt="">



  <img *ngIf="name == 'settings' && !active" src="../../../../assets/menu/v2/fs-settings.svg" alt="">
  <img *ngIf="name == 'settings' && active" src="../../../../assets/menu/v2/fs-settings-active.svg" alt="">

  <img *ngIf="name == 'helpAndFeedback' && !active" src="../../../../assets/menu/v2/fs-helpAndFeedback.svg" alt="">
  <img *ngIf="name == 'helpAndFeedback' && active" src="../../../../assets/menu/v2/fs-helpAndFeedback-active.svg"
    alt="">

  <img *ngIf="name == 'budget' && !active" src="../../../../assets/menu/v2/fs-budget.svg" alt="">
  <img *ngIf="name == 'budget' && active" src="../../../../assets/menu/v2/fs-budget-active.svg" alt="">

  <img *ngIf="name == 'logOut' && !active" src="../../../../assets/menu/v2/fs-logOut.svg" alt="">
  <img *ngIf="name == 'logOut' && active" src="../../../../assets/menu/v2/fs-logOut-active.svg" alt="">

</div>