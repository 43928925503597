import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { EstimateService } from './app-pricing-estimator/shared/services/estimate.service';
import { ProfileService } from './app-profile/shared/services/profile.service';
import { ProjectService } from './core/shared/services/project.service';
import { SessionService } from './core/shared/services/session.service';
import { getProjectSlug } from './core/shared/utils/utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'faststartup-circuit-web';

  constructor(
    private projectService: ProjectService,
    private estimateService: EstimateService,
    private sessionService: SessionService,
    private profileService: ProfileService
  ) { }

  ngOnInit() {
    this.sessionService._isAuthenticatedSubject
      .pipe(
        switchMap((isAuthenticated) => {
          return this.profileService.refreshProfile(null)
        })
      )
      .subscribe((isAuthenticated) => {
      });

    this.profileService.refreshProfile(null)
      .subscribe();
  }
}
