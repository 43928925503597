<section>
    <div class="timeline-summary-container" #timelineSummaryContainer>
        <app-fs-timeline-summary [showOpenButton]="true" (onOpen)="toggle()"></app-fs-timeline-summary>
    </div>

    <div class="timeline-details-container" #timelineDetailsContainer>
        <app-fs-timeline-chart #timelineChart></app-fs-timeline-chart>
    </div>
</section>














<!-- <section *ngIf="timelineIsAvailable">
    <div class="timeline-header">
        <div class="sprint-name">Sprint #{{ project?.currentSprint }}</div>
        <div class="timeline-summary">
            <div class="service-items">
                <div class="service-item" *ngFor="let service of services" [style]="service.summaryStyle">
                    <div class="service-fill" [style]="service.progressStyle">
                    </div>
                    <div class="service-name">
                        {{ service.name }}
                    </div>
                </div>
            </div>
            <div class="timeline-footer">
                <button (click)="toggleTimelineDetails()">
                    <img src="../../../../assets/fs-arrow-down-gray.svg" alt="">
                    Expand
                </button>
            </div>
        </div>
    </div>

    <div class="timeline-content" [style.height]="timelineContentHeight">
        <div class="timeline-panel" #timelineDetails>
            <div class="timeline-heading">
                estimated timeline: <span>{{ project?.totalSprints }} Sprints ({{ project?.totalSprints * 3 }}
                    weeks)</span>
            </div>

            <app-fs-timeline-chart #timelineChart (contentChange)="timelineChartContentChange()"
                [serviceProgressAnimation]="true" [taskProgressAnimation]="true">
            </app-fs-timeline-chart>

            <div class="timeline-footer">
                <div class="footer-note">
                    *Consultation takes 1-2 weeks, each sprint is 3 weeks
                </div>
                <div class="footer-action">
                    <button (click)="toggleTimelineDetails()">
                        <img src="../../../../assets/fs-arrow-down-gray.svg" alt=""> Hide
                    </button>
                </div>
            </div>
        </div>
    </div>
</section> -->