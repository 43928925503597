import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BodyComponent } from './components/body/body.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { SiteLayoutComponent } from './layouts/site-layout/site-layout.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { ModalComponent } from './components/modal/modal.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SharedModule } from '../shared/shared.module';
import { BuildComponent } from './components/build/build.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MenuIconComponent } from './components/menu-icon/menu-icon.component';
import { ToInitialsPipe } from './shared/pipes/to-initials.pipe';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { NavigationMenuComponent } from './components/navigation-menu/navigation-menu.component';
import { NavBodyComponent } from './components/nav-body/nav-body.component';
import { DragAndDropDirective } from './shared/directives/drag-and-drop.directive';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { OverlayFileUploaderComponent } from './components/overlay-file-uploader/overlay-file-uploader.component';

@NgModule({
  declarations: [
    SiteLayoutComponent,
    AuthLayoutComponent,
    BodyComponent,
    HeaderComponent,
    FooterComponent,
    NavMenuComponent,
    ModalComponent,
    BuildComponent,
    UserMenuComponent,
    MenuIconComponent,
    ToInitialsPipe,
    SearchInputComponent,
    NavigationMenuComponent,
    NavBodyComponent,
    DragAndDropDirective,
    NotificationsComponent,
    OverlayFileUploaderComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    RouterModule,
    ImageCropperModule,
  ],
  exports: [BodyComponent]
})
export class CoreModule { }
