import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode from "jwt-decode";
import { BehaviorSubject, fromEvent } from 'rxjs';
import { IEstimate } from 'src/app/app-pricing-estimator/shared/models/estimate.model';
import { IProject } from '../models/project.model';
import { IQuestionnaire } from '../models/questionnaire.model';
import { ISessionUser } from '../models/session-user.model';
import { ISessionToken } from '../models/token.model';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private tokenKey = 'fs_token';
  private tokenExpireDateKey = 'fs_token_exp';
  private userKey = 'fs_user';
  private projectKey = 'fs_project';
  private estimateKey = 'fs_estimate';
  private questionnaireKey = 'fs_questionnaire';

  private paymentSourceKey = 'payment_source';
  private confettyKey = 'fs_confetti';

  private _tokenSubject: BehaviorSubject<ISessionToken>;
  private _userSubject: BehaviorSubject<ISessionUser>;
  private _estimateSubject: BehaviorSubject<IEstimate>;
  private _questionnaireSubject: BehaviorSubject<IQuestionnaire>;

  public _projectSubject: BehaviorSubject<IProject>;
  public _isAuthenticatedSubject: BehaviorSubject<boolean>;

  constructor(
    private router: Router
  ) {
    this.initStorage();

    fromEvent(window, 'storage')
      .pipe()
      .subscribe((e) => {
        this.checkStorage();
      });
  }

  get isAuthenticated(): boolean {
    return this._isAuthenticatedSubject.value && this.project?.id != null;
  }

  get token(): ISessionToken {
    return this._tokenSubject.value;
  }

  get user(): ISessionUser {
    return this._userSubject.value;
  }

  get project(): IProject {
    return this._projectSubject.value;
  }

  get estimate(): IEstimate {
    return this._estimateSubject.value;
  }

  get questionnaire(): IQuestionnaire {
    return this._questionnaireSubject.value;
  }

  private parseToken(token: string): ISessionToken {
    if (token) {
      let _token = jwt_decode(token, {}) as any;
      return {
        id: _token.id,
        value: token,
        customer: _token.customer,
        exp: _token.exp,
        iat: _token.iat
      } as ISessionToken
    }
    return null;
  }

  private checkStorage() {
    let token = localStorage.getItem(this.tokenKey)
    let user = localStorage.getItem(this.userKey)
    let project = localStorage.getItem(this.projectKey)
    let estimate = localStorage.getItem(this.estimateKey)
    let questionnaire = localStorage.getItem(this.questionnaireKey)

    if (token != this.token.id ||
      JSON.stringify(user) != JSON.stringify(this.user) ||
      JSON.stringify(project) != JSON.stringify(this.project) ||
      JSON.stringify(estimate) != JSON.stringify(this.estimate) ||
      JSON.stringify(questionnaire) != JSON.stringify(this.questionnaire)
    ) {
      this._removeSession()
      this.router.navigate(['/auth/login']);
    }
  }

  private initStorage() {
    this._isAuthenticatedSubject = new BehaviorSubject<boolean>(!!localStorage.getItem(this.tokenKey));
    this._tokenSubject = new BehaviorSubject<ISessionToken>(this.parseToken(localStorage.getItem(this.tokenKey)));
    this._userSubject = new BehaviorSubject<ISessionUser>(JSON.parse(localStorage.getItem(this.userKey)));
    this._projectSubject = new BehaviorSubject<IProject>(JSON.parse(localStorage.getItem(this.projectKey)));
    this._estimateSubject = new BehaviorSubject<IEstimate>(JSON.parse(localStorage.getItem(this.estimateKey)));
    this._questionnaireSubject = new BehaviorSubject<IQuestionnaire>(JSON.parse(localStorage.getItem(this.questionnaireKey)));
  }

  _saveUser(user: ISessionUser) {
    localStorage.setItem(this.userKey, JSON.stringify(user));
    this._userSubject.next(user);
  }

  _saveProject(project: IProject) {
    localStorage.setItem(this.projectKey, JSON.stringify(project));
    this._projectSubject.next(project);
  }

  _saveEstimate(estimate: IEstimate) {
    // throw new Error('Something bad happened');
    localStorage.setItem(this.estimateKey, JSON.stringify(estimate));
    this._estimateSubject.next(estimate);
  }

  _saveQuestionnaire(questionnaire: IQuestionnaire) {
    localStorage.setItem(this.questionnaireKey, JSON.stringify(questionnaire));
    this._questionnaireSubject.next(questionnaire);
  }

  _saveToken(token: string) {
    let _token = this.parseToken(token);
    localStorage.setItem(this.tokenKey, token);
    localStorage.setItem(this.tokenExpireDateKey, _token.exp?.toString());
    this._tokenSubject.next(_token);
    this._isAuthenticatedSubject.next(!!token);
  }

  _removeSession() {
    localStorage.removeItem(this.tokenKey);
    localStorage.removeItem(this.tokenExpireDateKey);
    localStorage.removeItem(this.userKey);
    localStorage.removeItem(this.projectKey);
    localStorage.removeItem(this.estimateKey);
    localStorage.removeItem(this.questionnaireKey);

    this._tokenSubject.next(null);
    this._userSubject.next(null);
    this._projectSubject.next(null);
    this._estimateSubject.next(null);
    this._questionnaireSubject.next(null);
    this._isAuthenticatedSubject.next(false);
  }


  savePaymentSource(paymentSource: string) {
    localStorage.setItem(this.paymentSourceKey, paymentSource);
  }

  saveConfetti() {
    localStorage.setItem(this.confettyKey, '1');
  }

  getConfetti() {
    return localStorage.getItem(this.confettyKey);
  }

  removeConfetti() {
    localStorage.removeItem(this.confettyKey);
  }

  private sessionIsExpired(): boolean {
    let sessionExpireDate = this.getSessionExpireDate();
    if (sessionExpireDate == '') { return true; }

    return this.currentUtcEpoch() > +sessionExpireDate;
  }

  private currentUtcEpoch(): number {
    return Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds(), new Date().getUTCMilliseconds()) / 1000;
  }

  private getSessionExpireDate() {
    return localStorage.getItem(this.tokenExpireDateKey);
  }

  private getSession() {
    return localStorage.getItem(this.tokenKey);
  }
}
