import { Component, Input, OnInit } from '@angular/core';
import { IIncludedService } from '../../models/included-service.model';

@Component({
  selector: 'app-fs-included-services',
  templateUrl: './fs-included-services.component.html',
  styleUrls: ['./fs-included-services.component.scss']
})
export class FsIncludedServicesComponent implements OnInit {

  @Input('services') services: IIncludedService[]

  constructor() { }

  ngOnInit(): void {
  }

}
