import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { handleError } from 'src/app/shared/utils/utils';
import { environment } from 'src/environments/environment';
import { IProject } from '../models/project.model';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  private baseUrl = `${environment.circuitApiUrl}/projects`;

  constructor(
    private sessionService: SessionService,
    private http: HttpClient
  ) { }

  get(slug: string = ''): Observable<IProject> {
    const url = `${this.baseUrl}/${slug}`;
    return this.http.get(url).pipe(
      map((resp: any) => {
        let project = this.mapProject(resp);
        return project;
      }),
      catchError(handleError)
    )
  }

  startProject(estimate: string,
    strategyService: boolean, designService: boolean, engineeringService: boolean,
    speedPriority: boolean, budgetPriority: boolean, featuresPriority: boolean,
    userPreference: string): Observable<void> {
    const url = `${this.baseUrl}/${this.sessionService.project.id}/start`;
    let data = {
      estimate: estimate,
      userPreference,
      servicesIncluded: {
        strategy: strategyService,
        design: designService,
        engineering: engineeringService
      },
      priority: {
        speed: speedPriority,
        budget: budgetPriority,
        features: featuresPriority
      },
    };
    return this.http.put(url, data)
      .pipe(
        map((resp: any) => { return; }),
        catchError(handleError)
      )
  }

  changePriority(speed: boolean, budget: boolean, features: boolean): Observable<void> {
    const url = `${this.baseUrl}/${this.sessionService.project.id}/priority`;
    let data = {
      priority: {
        speed: speed,
        budget: budget,
        features: features
      }
    };
    return this.http.put(url, data)
      .pipe(
        map((resp: any) => { return; }),
        catchError(handleError)
      )
  }

  applyEstimate(estimate: string): Observable<void> {
    const url = `${this.baseUrl}/${this.sessionService.project.id}`
    let data = { estimate: estimate };
    return this.http.patch(url, data)
      .pipe(
        map((resp: any) => { return; }),
        catchError(handleError)
      )
  }


  // "status": "CallScheduled",
  // "estimate": "string",
  // "servicesIncluded": {
  //   "strategy": true,
  //   "design": true,
  //   "engineering": true
  // },
  // "priority": {
  //   "budget": true,
  //   "features": true,
  //   "speed": true
  // }

  private mapProject(project) {
    return project as IProject;
  }
}
