<div class="timeline-summary">
    <div class="timeline-summary-header">
        <div class="timeline-summary-name">Sprint 2</div>
    </div>

    <div class="timeline-summary-body">
        <div class="timeline-summary-line">
            <div class="timeline-summary-progress"></div>
        </div>
        <div class="timeline-summary-flag">
            <img src="../../../../assets/fs-timeline-flag.svg" alt="">
        </div>
    </div>

    <div class="timeline-summary-footer">
        <button *ngIf="showOpenButton" (click)="openDetails()">
            <img src="../../../../assets/fs-open-timeline-black.svg" alt="">
        </button>
    </div>
</div>