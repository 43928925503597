import { ComponentFactoryResolver, EventEmitter, Injectable, Output, ViewContainerRef } from '@angular/core';
import { from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ModalPositionType } from '../models/modal-position.model';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  componentFactoryResolver: ComponentFactoryResolver;
  modalContent: ViewContainerRef;

  @Output('onModalShow') onModalShow: EventEmitter<any> = new EventEmitter();
  @Output('onModalClose') onModalClose: EventEmitter<any> = new EventEmitter();
  @Output('onModalPositionSet') onModalPositionSet: EventEmitter<ModalPositionType> = new EventEmitter();

  constructor(private cfr: ComponentFactoryResolver) {
    this.componentFactoryResolver = cfr;
  }

  private async getComponent(instance) {
    return this.extractFirstProperty(await instance);
  }

  showModal(instance: any, position?: ModalPositionType): Observable<any> {
    if (position == null) { position = ModalPositionType.center; }
    let _component = this.getComponent(instance);
    return from(_component)
      .pipe(
        mergeMap(x => {
          let componentFactory = this.cfr.resolveComponentFactory(x);
          this.modalContent?.clear();
          const { instance } = this.modalContent.createComponent(componentFactory);
          this.onModalShow.emit(instance);
          this.onModalPositionSet.emit(position);
          return from([instance]);
        })
      )
  }

  hideModal() {
    this.onModalClose.emit();
  }

  private extractFirstProperty(instance) {
    return instance[Object.keys(instance)[0]];
  }
}
