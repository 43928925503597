import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-fs-checkbox',
  templateUrl: './fs-checkbox.component.html',
  styleUrls: ['./fs-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FsCheckboxComponent implements OnInit {

  @Output('onChange') onChange: EventEmitter<any> = new EventEmitter<any>();
  @Input('checked') checked: boolean;
  @Input('selectable') selectable: boolean;
  @Input('noOpacity') noOpacity: boolean;
  @Input('name') name: string;

  constructor() { }

  ngOnInit(): void {
  }

  change(event) {
    this.onChange.emit(event);
  }
}
