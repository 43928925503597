import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor() { }

  pricingEstimatorComplete = new Subject();
  questionnaireComplete = new Subject();
  sprintComplete = new Subject();
  projectGet = new Subject();
}
