<div class="fs-select-container">
    <div class="width-fix" *ngIf="isOpen">{{ widthFix }}</div>
    <div class="fs-select-header" (click)="toggleSelect()">
        <span>{{ selectedLabel }}</span>
        <i [ngClass]="{'open' : isOpen}">
            <img src="../../../../assets/fs-arrow-down-black.svg" alt="">
        </i>
    </div>
    <div class="fs-select-body" [style.height]="bodyHeight">
        <div class="select-items" #selectContent>
            <ng-container *ngFor="let selectOption of selectOptions">
                <div class="select-item" *ngIf="!selectOption.selected" (click)="changeSelection(selectOption.key)">
                    {{ selectOption.value }}
                </div>
            </ng-container>
        </div>
    </div>
</div>