import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-fs-dropdown',
  templateUrl: './fs-dropdown.component.html',
  styleUrls: ['./fs-dropdown.component.scss']
})
export class FsDropdownComponent implements OnInit {

  @Input('items') items: any;
  @Input('label') label: string;

  @Output('myChange') myChange: EventEmitter<string | number> = new EventEmitter();

  @ViewChild('dropDownContent') dropDownContent: ElementRef;
  @ViewChild('dropDownOpener') dropDownOpener: ElementRef;

  dropDownHeight = '';
  dropDownIsOpened = false;

  constructor() {
    fromEvent(document, 'click')
      .subscribe((e) => {
        if (this.dropDownOpener.nativeElement.contains(e.target) == false) {
          if (this.dropDownIsOpened) {
            this.dropDownHeight = '0px';
            this.dropDownIsOpened = false;
          }
        }
      })
  }

  get selectedLabel(): string {
    return this.items.filter(x => x.selected == true)[0]?.value ?? 'Select Option';
  }

  get itemsToSelect() {
    return this.items.filter(x => x.selected != true);
  }

  ngOnInit(): void {
  }

  changeDropDown(key) {
    this.items.forEach(item => { item.selected = false; });
    this.items.filter(x => x.key == key)[0].selected = true;
    this.toggleDropDown();
    this.myChange.emit(key);
  }

  toggleDropDown() {
    this.dropDownIsOpened = !this.dropDownIsOpened;
    this.dropDownHeight = `${this.dropDownIsOpened ? this.dropDownContent.nativeElement.offsetHeight : 0}px`;
  }
}
