import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FsBudgetInfoComponent } from './components/fs-budget-info/fs-budget-info.component';
import { FsCardNumberComponent } from './components/fs-card-number/fs-card-number.component';
import { FsCardComponent } from './components/fs-card/fs-card.component';
import { FsCheckboxComponent } from './components/fs-checkbox/fs-checkbox.component';
import { FsCircleProgressComponent } from './components/fs-circle-progress/fs-circle-progress.component';
import { FsCircleRangeComponent } from './components/fs-circle-range/fs-circle-range.component';
import { FsDropdownMenuComponent } from './components/fs-dropdown-menu/fs-dropdown-menu.component';
import { FsDropdownComponent } from './components/fs-dropdown/fs-dropdown.component';
import { FsEmailInputComponent } from './components/fs-email-input/fs-email-input.component';
import { FsImageCropperComponent } from './components/fs-image-cropper/fs-image-cropper.component';
import { FsIncludedServicesComponent } from './components/fs-included-services/fs-included-services.component';
import { FsIntensityRangeComponent } from './components/fs-intensity-range/fs-intensity-range.component';
import { FsLoaderComponent } from './components/fs-loader/fs-loader.component';
import { FsPaymentFormComponent } from './components/fs-payment-form/fs-payment-form.component';
import { FsProgressLineComponent } from './components/fs-progress-line/fs-progress-line.component';
import { FsRangeComponent } from './components/fs-range/fs-range.component';
import { FsSelectComponent } from './components/fs-select/fs-select.component';
import { FsSkewButtonComponent } from './components/fs-skew-button/fs-skew-button.component';
import { FsSwitchCheckboxComponent } from './components/fs-switch-checkbox/fs-switch-checkbox.component';
import { FsTextInputComponent } from './components/fs-text-input/fs-text-input.component';
import { FsTimelineChartComponent } from './components/fs-timeline-chart/fs-timeline-chart.component';
import { FsTimelineComponent } from './components/fs-timeline/fs-timeline.component';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { FsTimelineSummaryComponent } from './components/fs-timeline-summary/fs-timeline-summary.component';
import { FsMarkOnScreenIconComponent } from './components/fs-mark-on-screen-icon/fs-mark-on-screen-icon.component';
import { FsLeaveCommentIconComponent } from './components/fs-leave-comment-icon/fs-leave-comment-icon.component';
import { FsSelectListComponent } from './components/fs-select-list/fs-select-list.component';
import { FsBackButtonComponent } from './components/fs-back-button/fs-back-button.component';
import { FsTriangleArrowComponent } from './components/fs-triangle-arrow/fs-triangle-arrow.component';
import { FsActionButtonComponent } from './components/fs-action-button/fs-action-button.component';
import { FsTriangleArrowButtonComponent } from './components/fs-triangle-arrow-button/fs-triangle-arrow-button.component';
import { FsSelectButtonComponent } from './components/fs-select-button/fs-select-button.component';
import { FsNumberPipe } from './pipes/fs-number.pipe';


@NgModule({
  declarations: [
    FsTextInputComponent,
    FsEmailInputComponent,
    FsSkewButtonComponent,
    FsCheckboxComponent,
    FsIntensityRangeComponent,
    FsRangeComponent,
    FsDropdownMenuComponent,
    FsProgressLineComponent,
    FsCardComponent,
    FsLoaderComponent,
    FsCardNumberComponent,
    FsBudgetInfoComponent,
    FsImageCropperComponent,
    FsPaymentFormComponent,
    FsCircleRangeComponent,
    FsTimelineComponent,
    FsTimelineChartComponent,
    FsIncludedServicesComponent,
    FsSwitchCheckboxComponent,
    FsCircleProgressComponent,
    FsSelectComponent,
    FsDropdownComponent,
    TimeAgoPipe,
    FsTimelineSummaryComponent,
    FsMarkOnScreenIconComponent,
    FsLeaveCommentIconComponent,
    FsSelectListComponent,
    FsBackButtonComponent,
    FsTriangleArrowComponent,
    FsActionButtonComponent,
    FsTriangleArrowButtonComponent,
    FsSelectButtonComponent,
    FsNumberPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
  ],
  exports: [
    FsEmailInputComponent,
    FsTextInputComponent,
    FsSkewButtonComponent,
    FsCheckboxComponent,
    FsIntensityRangeComponent,
    FsRangeComponent,
    FsDropdownMenuComponent,
    FsProgressLineComponent,
    FsCardComponent,
    FsPaymentFormComponent,
    FsBudgetInfoComponent,
    FsLoaderComponent,
    FsImageCropperComponent,
    FsCircleRangeComponent,
    FsTimelineComponent,
    FsTimelineChartComponent,
    FsIncludedServicesComponent,
    FsSelectComponent,
    CommonModule,
    ReactiveFormsModule,
    FsDropdownComponent,
    FsSwitchCheckboxComponent,
    FsCircleProgressComponent,
    FsMarkOnScreenIconComponent,
    FsLeaveCommentIconComponent,
    FsSelectListComponent,
    FsTriangleArrowComponent,
    FsActionButtonComponent,
    FormsModule,
    TimeAgoPipe,
    FsNumberPipe,
    FsTriangleArrowButtonComponent,
    FsSelectButtonComponent
    // DecimalPipe
  ]
})
export class SharedModule { }
