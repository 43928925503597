import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MainContainerInitService {

  @Output('onInit') onInit: EventEmitter<void> = new EventEmitter();

  constructor() { }

  init() {
    this.onInit.emit();
  }
}
