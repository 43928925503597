import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-fs-card',
  templateUrl: './fs-card.component.html',
  styleUrls: ['./fs-card.component.scss']
})
export class FsCardComponent implements OnInit, OnChanges {

  @Input('label') label: string;
  @Input('viewState') viewState: string;
  @Input('form') form: FormGroup;

  private cardNumberIsValid: boolean;
  private expIsValid: boolean;
  private cvcIsValid: boolean;

  private cardNumberErrorMessage: string;
  private expErrorMessage: string;
  private cvcErrorMessage: string;

  private stripe: Stripe;
  private stripeElements: StripeElements;

  get isLoading(): boolean {
    //return true;
    return !this.form.get('cardNumberLastDigits').value;
  }

  @Output('onCardChange') onCardChange: EventEmitter<any> = new EventEmitter();
  @Output('onEditClick') onEditClick: EventEmitter<any> = new EventEmitter();

  get isEdit(): boolean { return this.viewState == 'edit'; }

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    if (this.isEdit) {
      this.loadStripe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.viewState?.previousValue != this.viewState) {
      if (this.viewState == 'edit') {
        this.loadStripe();
      }
    }
  }

  async loadStripe() {
    this.stripe = await loadStripe(environment.stripeKey);
    this.stripeElements = this.stripe.elements({
      fonts: [
        {
          family: 'gilroy',
          src: 'url(https://develop.faststartup.io/Gilroy-900.ttf) format("truetype")',
          weight: 'normal'
        },
        {
          family: 'Open Sans',
          weight: '400',
          src: 'local("Open Sans"), local("OpenSans"), url(https://fonts.gstatic.com/s/opensans/v13/cJZKeOuBrn4kERxqtaUH3ZBw1xU1rKptJj_0jans920.woff2) format("woff2")',
          unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215',
        },
      ]
    });

    let cvcAndExpiryStyle = {
      color: '#000000',
      fontSize: '14px',
      fontFamily: '"gilroy"',
      fontWeight: '700',
      lineHeight: '31px',
      padding: '5px 0 7px 0',
      '::placeholder': {
        color: 'transparent',
      },

      ':focus::placeholder': {
        color: 'transparent',
      }
    };

    let cardNumberStyle = {
      color: '#000000',
      fontSize: '24px',
      fontFamily: 'gilroy',
      fontWeight: '700',
      lineHeight: '31px',
      padding: '5px 0 7px 0',
      '::placeholder': {
        color: 'transparent',
      },

      ':focus::placeholder': {
        color: 'transparent',
      },
    };

    let card = this.stripeElements.create('cardNumber', { style: { base: cardNumberStyle, invalid: { color: 'red' } } });
    let exp = this.stripeElements.create('cardExpiry', { style: { base: cvcAndExpiryStyle, invalid: { color: 'red' } } });
    let cvc = this.stripeElements.create('cardCvc', { style: { base: cvcAndExpiryStyle, invalid: { color: 'red' } } });

    card.mount('#card-number');
    exp.mount('#card-exp');
    cvc.mount('#card-cvc');

    card.on('change', (e) => {
      this.cardNumberIsValid = e.complete && !e.error;
      this.cardNumberErrorMessage = e.error?.message;
      this.emitCardChange();
    });

    exp.on('change', (e) => {
      this.expIsValid = e.complete && !e.error;
      this.expErrorMessage = e.error?.message;
      this.emitCardChange();
    });

    cvc.on('change', (e) => {
      this.cvcIsValid = e.complete && !e.error;
      this.cvcErrorMessage = e.error?.message;
      this.emitCardChange();
    });
  }

  redirectToEdit() {
    this.onEditClick.emit();
  }

  private emitCardChange() {
    this.onCardChange.emit({
      cardNumberIsValid: this.cardNumberIsValid,
      expIsValid: this.expIsValid,
      cvcIsValid: this.cvcIsValid,

      cardNumberErrorMessage: this.cardNumberErrorMessage,
      expErrorMessage: this.expErrorMessage,
      cvcErrorMessage: this.cvcErrorMessage,

      stripeElements: this.cardNumberIsValid && this.expIsValid && this.cvcIsValid ? this.stripeElements : null,
      stripe: this.cardNumberIsValid && this.expIsValid && this.cvcIsValid ? this.stripe : null,
    })
  }
}