import { Component, OnInit } from '@angular/core';
import { MainContainerDragService } from '../../shared/services/main-container-drag.service';
import { MainContainerInitService } from '../../shared/services/main-container-init.service';

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.scss']
})
export class SiteLayoutComponent implements OnInit {

  isOff = false;

  constructor(
    private mainContainerDragService: MainContainerDragService,
    private mainContainerInitService: MainContainerInitService
  ) { }

  ngOnInit(): void {
    this.mainContainerInitService.init();
  }

  toggle(isOff) {
    this.isOff = isOff;
  }

  onDragOver(evt) {
    this.mainContainerDragService.onDragOver.emit(evt);
  }

  onDragLeave(evt) {
    this.mainContainerDragService.onDragLeave.emit(evt);
  }

  onDragEnter(evt) {
    this.mainContainerDragService.onDragEnter.emit(evt);
  }
}
