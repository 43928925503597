import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, from, fromEvent, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, min, switchMap } from 'rxjs/operators';
import { EstimateService } from 'src/app/app-pricing-estimator/shared/services/estimate.service';
import { ProfileService } from 'src/app/app-profile/shared/services/profile.service';
import { QuestionnaireService } from 'src/app/app-questionnaire/shared/services/questionnaire.service';
import { ProjectService } from 'src/app/core/shared/services/project.service';
import { SessionService } from 'src/app/core/shared/services/session.service';
import { handleError } from 'src/app/shared/utils/utils';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private baseUrl = `${environment.circuitApiUrl}/auth`;

  constructor(
    private http: HttpClient,
    private sessionService: SessionService,
    private profileService: ProfileService,
    private projectService: ProjectService,
    private questionnaireService: QuestionnaireService,
    private estimateService: EstimateService
  ) { }

  login(email: string, password: string): Observable<boolean> {
    const url = `${this.baseUrl}/local`;
    let projectId = this.sessionService.project.id;

    return this.http.post(url, {
      email: email,
      password: password,
      project: projectId
    }).pipe(
      switchMap((resp: any) => {
        if (resp && resp.token) {
          this.sessionService._saveToken(resp.token)
          return of(true)
            .pipe(
              mergeMap((r) => {
                return this.profileService.refreshProfile(this.sessionService.project.slug)
              }),
              map(() => { return true; })
            );
        }
        return of(false)
      }),
      catchError(handleError)
    )
  }

  registerWithEstimate(
    email: string, fullName: string, projectName: string,
    estimateId: string,
    serviceStrategy: boolean, serviceDesign: boolean, serviceEngineering: boolean,
    priorityBudget: boolean, priorityFeatures: boolean, prioritySpeed: boolean):
    Observable<string> {

    const url = `${this.baseUrl}/local/register`;

    let data = {
      email: email,
      name: fullName,
      project: {
        estimate: estimateId,
        name: projectName,
        servicesIncluded: {
          strategy: serviceStrategy,
          design: serviceDesign,
          engineering: serviceEngineering
        },
        priority: {
          budget: priorityBudget,
          features: priorityFeatures,
          speed: prioritySpeed
        }
      },
    };

    return this.http.post(url, data)
      .pipe(
        map((resp: any) => { return resp.slug; }),
        catchError(handleError)
      );
  }

  registerWithoutEstimate(email: string, fullName: string, projectName: string): Observable<string> {
    const url = `${this.baseUrl}/local/register`;

    let data = {
      email: email,
      name: fullName,
      projectName: projectName
    };

    return this.http.post(url, data)
      .pipe(
        map((resp: any) => { return resp.slug; }),
        catchError(handleError)
      );
  }

  registerWithInvitationToken(token: string, email: string, fullName: string): Observable<void> {
    const url = `${this.baseUrl}/local/register`;
    let data = {
      invitationToken: token,
      email: email,
      name: fullName
    };
    return this.http.post(url, data)
      .pipe(
        map((resp: any) => { return; }),
        catchError(handleError)
      );
  }

  sendPassword(email: string): Observable<void> {
    return this.resendPassword(email);
  }

  resendPassword(email: string): Observable<void> {
    let projectId = this.sessionService.project?.id;
    const url = `${this.baseUrl}/local`;
    return this.http.post(url, { email: email, project: projectId })
      .pipe(
        map((resp: any) => { return; }),
        catchError(handleError)
      )
  }

  refreshToken(): Observable<void> {
    const url = `${this.baseUrl}/refresh-token`;
    return this.http.post(url, {})
      .pipe(
        map((resp: any) => {
          this.sessionService._saveToken(resp.token);
          return;
        }),
        catchError(handleError)
      )
  }
}
