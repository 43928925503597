import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/app-auth/shared/services/auth.service';
import { ProfileDataService } from 'src/app/app-profile/shared/services/profile-data.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { ISessionUser } from '../../shared/models/session-user.model';
import { SessionService } from '../../shared/services/session.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit, OnDestroy {

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  navBodyMinHeight = null;

  @ViewChild('navBodyPanel') navBodyPanel: ElementRef;

  user: ISessionUser;
  projectName: string;

  navBodyTransform = 'translateX(0)';

  navMenus = [
    { id: 'home', name: 'Home', icon: 'home', locked: true, active: true, link: ['/'], activeRoutes: ['/', '/questionnaire', '/pricing-estimator'] },
    { id: 'taskList', name: 'Task List', icon: 'task-list', locked: false, active: false, link: ['/tasks'], activeRoutes: ['/tasks'] },
    {
      id: 'strategy', name: 'Strategy', icon: 'strategy', locked: false, active: false,
      children: [
        // { id: 'briefingSummary', name: 'Brief Summary', locked: false },
        { id: 'sitemap', name: 'Sitemap', locked: false, link: ['strategy/sitemap'] as any },
        { id: 'userPersona', name: 'User Personas', locked: false, link: ['strategy/user-personas'] as any },
      ]
    },
    {
      id: 'design', name: 'Design', icon: 'design', locked: false, active: false,
      children: [
        { id: 'wireframe', name: 'Wireframes', locked: false, link: ['/design/wireframes'] } as any,
        { id: 'visualIdentity', name: 'Visual Identity', locked: false, link: ['/design/visual-identity'] } as any,
        { id: 'highFidelity', name: 'High Fidelity', locked: false, link: ['/design/product-design'] } as any
      ]
    },
    {
      id: 'engineering', name: 'Engineering', icon: 'engineering', locked: true, active: false,
      children: [
        { id: 'apiDoc', name: 'Api Doc', locked: false },
        { id: 'website', name: 'Website', locked: false }
      ]
    },
    { id: 'billing', name: 'Billing', icon: 'billing', locked: false, active: false, link: ['/billing'], activeRoutes: ['/billing'] },
  ];


  menus = [
    { name: 'Home', icon: 'home', active: false, link: ['/'], children: [], activeRoutes: ['/', '/questionnaire', '/pricing-estimator'] },
    { name: 'My List', icon: 'myList', active: false, children: [] },
    {
      name: 'Requests', icon: 'requests', active: false,
      children: [
        { name: 'Feedback', active: false, link: ['/request/feedbacks'], activeRoutes: ['/request/feedbacks'] },
        { name: 'Feature Requests', active: false, link: ['/request/feature-requests'], activeRoutes: ['/request/feature-requests'] },
        { name: 'From FastStartup', active: false },
      ]
    },
    { name: 'Task List', icon: 'taskList', link: ['/tasks'], active: false, children: [], activeRoutes: ['/tasks'] },
    { name: 'Strategy', icon: 'strategy', active: false, children: [] },
    {
      name: 'Design', icon: 'design', active: false,
      children: [
        { name: 'Visual Identity', active: false, link: ['/design/visual-identity'], activeRoutes: ['/design/visual-identity'] }
      ]
    },
    { name: 'Engineering', icon: 'engineering', locked: true, active: false, children: [] },
    { name: 'Files', icon: 'files', locked: false, active: false, children: [], link: ['/files'] },
  ];

  settingMenus = [
    { name: 'Account', icon: 'account', active: false, children: [] },
    { name: 'Team', icon: 'team', active: false, link: ['/team'], children: [], activeRoutes: ['/team'] },
    { name: 'Project', icon: 'project', active: false, children: [] },
    { name: 'Budget', icon: 'budget', active: false, children: [] },
    { name: 'Billing', icon: 'billing', active: false, link: ['/billing'], children: [], activeRoutes: ['/billing/edit'] },
    { name: 'Legal', icon: 'legal', active: false, children: [] },
    { name: 'Notifications', icon: 'notifications', active: false, children: [] },
  ]

  @ViewChildren("childNav") childNavs: QueryList<ElementRef>;
  @ViewChildren("menuChildPanel") menuChildPanels: QueryList<ElementRef>;


  constructor(
    private sessionService: SessionService,
    private modalService: ModalService,
    private router: Router,
    private profileDataService: ProfileDataService,
  ) { }

  ngOnInit(): void {
    this.user = this.sessionService.user;
    this.projectName = this.sessionService.project?.name ?? 'asd';

    this.manageMenuItemActiveStatus();

    this.router.events.subscribe(() => {
      this.manageMenuItemActiveStatus();
    });

    this.profileDataService.profile
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.modalService.hideModal();
        this.user = this.sessionService.user;

        this.navMenus.forEach((navMenuItem) => {
          let menuRoot = this.sessionService.user.menu[navMenuItem.id];
          if (navMenuItem.children) {
            navMenuItem.children.forEach((child) => { child.locked = !menuRoot[child.id]; });
            navMenuItem.locked = navMenuItem.children.map(x => x.locked).filter(x => x == false).length == 0;
          } else {
            navMenuItem.locked = !menuRoot;
          }
        });
      });

    setTimeout(() => {
      this.navBodyMinHeight = this.navBodyPanel.nativeElement.offsetHeight;
    }, 10);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  openSettings() {
    this.navBodyTransform = 'translateX(-50%)';
  }

  navigateToMainMenu() {
    this.navBodyTransform = 'translateX(0)';
  }

  menuClick(menu, index) {
    if (menu.children?.length > 0 && !menu.locked) {
      menu.isOpen = !menu.isOpen;
      menu.childHeight = `${menu.isOpen ? this.childNavs.find((_, i) => i == index).nativeElement.offsetHeight + 15 : 0}px`;
    } else {
      this.router.navigate(menu.link);
    }
  }

  anyChildIsActive(menu) {
    return menu && menu?.children && menu.children.filter(x => x.active == true).length > 0;
  }

  toggleMenuChild(menu, index) {
    if (menu.children?.length > 0 && !menu.locked) {
      menu.open = !menu.open;
      menu.childHeight = menu.open ? this.menuChildPanels.find((_, i) => i == index).nativeElement.offsetHeight + 0 : 0;
    } else {
      if (menu.link) {
        this.router.navigate(menu.link);
      }
    }
  }

  childIsSelected(menu) {
    return menu.children && menu.children.filter(x => x.active == true).length > 0;
  }

  private manageMenuItemActiveStatus() {

    [...this.menus, ...this.settingMenus].forEach((navMenuItem) => {
      if (navMenuItem.activeRoutes) {
        navMenuItem.active = navMenuItem.activeRoutes.indexOf(this.router.url) != -1;
      }
      if (navMenuItem.children) {
        navMenuItem.children.forEach((x) => {
          if (x.activeRoutes) {
            x.active = x.activeRoutes.indexOf(this.router.url) != -1;
          }
        })
      }
    });

  }
}
