<div class="fs-range">
    <label *ngIf="label">{{ label }}</label>
    <div class="range">

        <div class="range-wrap">
            <input type="range" [min]="min" [max]="max" step="1" [value]="value" (input)="oninput($event)">
        </div>

        <div class="range-panel">
            <div *ngIf="showRangeProgress" class="range-progress" [style.width]="progressWidth"
                [ngClass]="{'full' : isFull}"></div>
        </div>
    </div>
</div>