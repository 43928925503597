import { ComponentFactoryResolver, EventEmitter, Injectable, Output, ViewContainerRef } from '@angular/core';
import { from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  notificationContainer: ViewContainerRef;

  @Output('onNotificationShow') onNotificationShow: EventEmitter<any> = new EventEmitter();

  constructor(
    private cfr: ComponentFactoryResolver
  ) { }

  showNotification(instance: any): Observable<any> {
    let _component = this.getComponent(instance);
    return from(_component)
      .pipe(
        mergeMap(x => {
          let componentFactory = this.cfr.resolveComponentFactory(x);
          // this.notificationContainer.clear();

          const component = this.notificationContainer.createComponent(componentFactory);
          const { instance }: { instance: any } = component;

          instance?.onClose?.subscribe(() => { component.destroy(); });

          this.onNotificationShow.emit(instance);
          return from([instance]);
        })
      )
  }

  private async getComponent(instance) {
    return this.extractFirstProperty(await instance);
  }

  private extractFirstProperty(instance) {
    return instance[Object.keys(instance)[0]];
  }
}
