import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-fs-switch-checkbox',
  templateUrl: './fs-switch-checkbox.component.html',
  styleUrls: ['./fs-switch-checkbox.component.scss']
})
export class FsSwitchCheckboxComponent implements OnInit {

  @Input('label') label: string;

  isOn = false;

  constructor() { }

  ngOnInit(): void {
  }

  toggle() {
    this.isOn = !this.isOn;
  }

}
