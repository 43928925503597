import { HttpErrorResponse, HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from "@angular/common/http";
import { throwError } from "rxjs";
import { IUploadProgress } from "src/app/core/shared/models/upload-progress.model";


export function copyToClipboard(msg: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = msg;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
}

export function handleError(error: HttpErrorResponse) {
    return throwError(error);
}

export function calculateState<T>(upload: IUploadProgress<T>, event: HttpEvent<unknown>): IUploadProgress<T> {
    if (isHttpProgressEvent(event)) {
        return {
            progress: event.total
                ? (100 * event.loaded) / event.total
                : upload.progress,
            state: 'IN_PROGRESS',
            response: null,
            loaded: event.loaded,
            total: event.total
        }
    }
    if (isHttpResponse(event)) {
        return {
            progress: 100,
            state: 'DONE',
            response: event.body as T
        }
    }



    return upload
}

export function isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
    return event.type === HttpEventType.Response
}

export function isHttpProgressEvent(
    event: HttpEvent<unknown>
): event is HttpProgressEvent {
    return (
        //event.type === HttpEventType.DownloadProgress ||
        event.type === HttpEventType.UploadProgress
    )
}