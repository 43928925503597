<div class="progress-bar">
    <div class="progress-line-panel">
        <div class="progress-line">
            <div class="progress-line-filled" [style.width]="progressWidth"
                [ngClass]="{'full' : currentStep == totalSteps}">

            </div>
        </div>

        <img src="../../../../assets/fs-flag.svg" alt="">
    </div>
</div>