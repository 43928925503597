import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-fs-circle-progress',
  templateUrl: './fs-circle-progress.component.html',
  styleUrls: ['./fs-circle-progress.component.scss']
})
export class FsCircleProgressComponent implements OnInit {

  @Input('percentage') percentage: number = 0;
  @Input('strokeWidth') strokeWidth: number = 4;
  @Input('showNumbers') showNumbers: boolean;
  @Input('strokeAnimation') strokeAnimation: boolean = true;

  @ViewChild('circleObject') circleObject: ElementRef;
  @ViewChild('progressCircle') progressCircle: ElementRef;

  animated: boolean;
  progressCountDown: number = 0;

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.initCircle();
    }, 10);
  }

  private initCircle() {
    let progressWidth = this.progressCircle.nativeElement.offsetWidth;
    let progressHeight = this.progressCircle.nativeElement.offsetHeight;
    let radius = (progressWidth / 2) - this.strokeWidth / 2;

    let circleElement = this.circleObject.nativeElement;

    circleElement.setAttribute('cx', progressWidth / 2);
    circleElement.setAttribute('cy', progressHeight / 2);
    circleElement.setAttribute('r', radius);

    circleElement.setAttribute('stroke-width', `${this.strokeWidth}px`);
    circleElement.setAttribute('stroke-dasharray', this.calculatePercentage(0, radius));
    circleElement.setAttribute('stroke-dashoffset', this.calculatePercentage(0, radius));
    this.setCssValue('--stroke-width', `${this.strokeWidth}px`);

    setTimeout(() => {
      this.animated = this.strokeAnimation;
      this.animateCircle(radius);

      let myInterval = setInterval(() => {
        this.progressCountDown += 1;
        if (this.progressCountDown == this.percentage) {
          clearInterval(myInterval);
        }
      }, (this.animated ? 1000 : 0) / this.percentage);
    }, this.strokeAnimation ? 500 : 0);
  }

  private animateCircle(radius) {
    let circleElement = this.circleObject.nativeElement;
    circleElement.setAttribute('stroke-dashoffset', this.calculatePercentage(this.percentage, radius));
  }

  private calculatePercentage(percent, radius) {
    let c = Math.PI * (radius * 2);
    if (percent < 0) { percent = 0; }

    percent = Math.max(percent, 0);
    percent = Math.min(percent, 100);

    return ((100 - percent) / 100) * c;
  }

  private setCssValue(variableName, value) {
    this.elementRef.nativeElement.style
      .setProperty(variableName, value);
  }
}
