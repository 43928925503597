<div #actionButtonContainer class="action-button-container" [ngClass]="{'opened' : opened, 
'left' : fromLeft,
'right' : fromRight,
'top' : fromTop,
'bottom' : fromBottom
}" [style.height]="containerHeight + 'px'" [style.width]="containerWidth + 'px'">

    <div class="content-panel">
        <button (click)="toggle()">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </button>

        <div #contentPanel class="action-button-content">
            <div class="actions">
                <button *ngFor="let action of actions" (click)="action.click()">
                    <i [ngStyle]="{'background-image': 'url(' + action.icon + ')'}"></i> {{ action.name }}
                </button>
            </div>
        </div>
    </div>

</div>