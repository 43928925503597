import { HttpClient, JsonpClientBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IQuestionnaire } from 'src/app/core/shared/models/questionnaire.model';
import { SessionService } from 'src/app/core/shared/services/session.service';
import { handleError } from 'src/app/shared/utils/utils';
import { environment } from 'src/environments/environment';
import { IBenchmark } from '../models/benchmark.model';
import { IBusinessModel } from '../models/business-model.model';
import { IFile } from '../models/file.model';
import { IKeyPoint } from '../models/key-point.model';
import { IProductValue } from '../models/product-value.model';

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireService {

  private baseUrl = `${environment.circuitApiUrl}/questionnaires`;

  constructor(
    private http: HttpClient,
    private sessionService: SessionService
  ) { }

  private get questionnaireId() {
    return this.sessionService.project.questionnaire;
  }

  get(id: string): Observable<IQuestionnaire> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.get(url).pipe(
      map((resp: any) => { return resp as IQuestionnaire; }),
      catchError(handleError)
    )
  }

  getKeyPoints(): Observable<IKeyPoint> {
    const url = `${this.baseUrl}/${this.questionnaireId}`;
    return this.http.get(url).pipe(
      map((resp: any) => { return { description: resp.keyPoints } as IKeyPoint; }),
      catchError(handleError)
    )
  }

  saveKeyPoints(description: string): Observable<void> {
    const url = `${this.baseUrl}/${this.questionnaireId}`;
    return this.http.patch(url, {
      keyPoints: description,
    }).pipe(
      map((resp: any) => { return; }),
      catchError(handleError)
    )
  }

  getBusinessModel(): Observable<IBusinessModel> {
    const url = `${this.baseUrl}/${this.questionnaireId}`;
    return this.http.get(url).pipe(
      map((resp: any) => {
        return {
          transactionFee: resp.businessModel?.transactionFee,
          advertisement: resp.businessModel?.advertisement,
          subscription: resp.businessModel?.subscription,
          oneTimePayment: resp.businessModel?.oneTimePayment
        } as IBusinessModel;
      }),
      catchError(handleError)
    )
  }

  saveBusinessModel(transactionFee: boolean, advertisement: boolean, subscription: boolean, oneTimePayment: boolean): Observable<void> {
    const url = `${this.baseUrl}/${this.questionnaireId}`;

    let data = {
      businessModel: {
        transactionFee: transactionFee,
        advertisement: advertisement,
        subscription: subscription,
        oneTimePayment: oneTimePayment
      }
    };

    return this.http.patch(url, data).pipe(
      map((resp: any) => { return; }),
      catchError(handleError)
    )
  }

  getProductValue(): Observable<IProductValue> {
    const url = `${this.baseUrl}/${this.questionnaireId}`;
    return this.http.get(url).pipe(
      map((resp: any) => {
        return {
          cost: resp.productValue?.cost,
          availability: resp.productValue?.availability,
          solidness: resp.productValue?.solidness,
          simpleness: resp.productValue?.simpleness
        } as IProductValue;
      }),
      catchError(handleError)
    )
  }

  saveProductValue(cost: number, availability: number, solidness: number, simpleness: number): Observable<void> {
    const url = `${this.baseUrl}/${this.questionnaireId}`;
    return this.http.patch(url, {
      productValue: {
        cost: cost,
        availability: availability,
        solidness: solidness,
        simpleness: simpleness
      }
    }).pipe(
      map((resp: any) => { return; }),
      catchError(handleError)
    )
  }

  getBenchmarks(): Observable<IBenchmark[]> {
    const url = `${this.baseUrl}/${this.questionnaireId}`;
    return this.http.get(url).pipe(
      map((resp: any) => {
        return resp.keyCompetitors?.map(benchmark => {
          return {
            website: benchmark.website,
            type: benchmark.type,
            significance: benchmark.significance
          }
        });
      }),
      catchError(handleError)
    )
  }

  saveBenchmarks(benchmarks: IBenchmark[]): Observable<void> {
    const url = `${this.baseUrl}/${this.questionnaireId}`;
    return this.http.patch(url, {
      keyCompetitors: benchmarks
    }).pipe(
      map((resp: any) => { return; }),
      catchError(handleError)
    )
  }

  getFiles(): Observable<IFile[]> {
    const url = `${this.baseUrl}/${this.questionnaireId}`;
    return this.http.get(url).pipe(
      map((resp: any) => { return resp.files?.map(file => { return JSON.parse(file); }); }),
      catchError(handleError)
    )
  }

  saveFiles(files: IFile[]): Observable<void> {
    const url = `${this.baseUrl}/${this.questionnaireId}`;
    return this.http.patch(url, {
      files: files.map(x => { return JSON.stringify(x); })
    }).pipe(
      map((resp: any) => { return; }),
      catchError(handleError)
    )
  }
}
