import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-fs-triangle-arrow-button',
  templateUrl: './fs-triangle-arrow-button.component.html',
  styleUrls: ['./fs-triangle-arrow-button.component.scss']
})
export class FsTriangleArrowButtonComponent implements OnInit {

  @Output('onClick') onClick: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  click(e) {
    this.onClick.emit();
  }
}
