import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '../services/session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private sessionService: SessionService
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (!this.sessionService.isAuthenticated) {
      this.sessionService._removeSession();
      this.router.navigate(['/auth/login']);
      return false;
    } return true;
  }

  // canActivateChild() {
  //   if (!this.sessionService.isAuthenticated) {
  //     this.router.navigate(['/auth/login']);
  //     return false;
  //   } return true;
  // }

}
