<section>
    <div class="container">
        <div class="included-services">
            <div class="included-service" *ngFor="let service of services">
                <h2>{{ service.name }}</h2>
                <div class="included-service-tasks">
                    <div class="included-service-task" *ngFor="let task of service.tasks">
                        <h3>
                            <img src="../../../../assets/fs-checked-yellow.svg" alt="">
                            {{ task.name }}
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>